import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import moment from "moment";
import {Line} from "react-chartjs-2";
import {useEffect, useRef, useState} from "react";
import "./lineGraph.css";
import 'moment/locale/fr';
import {colors} from "../Pie/Pie";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const adjustOpacity = (color, alpha) => {
    return color.replace(/[\d\.]+\)$/g, `${alpha})`);
};

const distanceToSegment = (x, y, x1, y1, x2, y2) => {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = (lenSq !== 0) ? (dot / lenSq) : -1;

    let xx, yy;

    if (param < 0) {
        xx = x1;
        yy = y1;
    } else if (param > 1) {
        xx = x2;
        yy = y2;
    } else {
        xx = x1 + param * C;
        yy = y1 + param * D;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
};

const MultiLineChart = ({metric}) => {
    const chartRef = useRef(null);
    const [selectedDataSets, setSelectedDatasets] = useState([]);
    const [hoveredDatasetIndex, setHoveredDatasetIndex] = useState(null);
    const [firstSelection, setFirstSelection] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);

    const fillData = () => {
        setSelectedDatasets(metric.datasets.map((_, index) => index));
        setFirstSelection(true)
    }
    // Initialiser tous les datasets comme sélectionnés au montage
    useEffect(() => {
        fillData();
    }, [metric.datasets]);

    const toggleDatasetSelection = (index) => {
        if (firstSelection) {
            setSelectedDatasets([index]);
            setFirstSelection(false);
            return;
        }

        setSelectedDatasets(prev => {
            const newSelection = [...prev];
            const pos = newSelection.indexOf(index);
            if (pos > -1) {
                newSelection.splice(pos, 1); // Remove from selection
            } else {
                newSelection.push(index); // Add to selection
            }
            return newSelection;
        });


    };

    const handleClickOnChart = (event) => {
        const chartInstance = chartRef.current;
        if (!chartInstance) return;

        const points = chartInstance.getElementsAtEventForMode(event.native, 'nearest', {intersect: false}, true);
        const mouseX = event.native.offsetX;
        const mouseY = event.native.offsetY;
        const tolerance = 10; // Vous pouvez ajuster cette tolérance en fonction de vos besoins

        let nearestIndex = null;
        let minDistance = Infinity;

        // Parcourir tous les datasets pour trouver le segment le plus proche
        chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chartInstance.getDatasetMeta(datasetIndex);
            if (!meta.hidden) {
                const data = meta.data;
                for (let i = 0; i < data.length - 1; i++) {
                    const distance = distanceToSegment(
                        mouseX, mouseY,
                        data[i].x, data[i].y,
                        data[i + 1].x, data[i + 1].y
                    );
                    if (distance < minDistance && distance < tolerance) {
                        minDistance = distance;
                        nearestIndex = datasetIndex;
                    }
                }
            }
        });

        // Si un segment proche a été trouvé, basculer la sélection de ce dataset
        if (nearestIndex !== null) {
            toggleDatasetSelection(nearestIndex);
        }
    };

    const handleHover = (event) => {

        const chartInstance = chartRef.current;
        if (!chartInstance) return;

        const mouseX = event.native.offsetX;
        const mouseY = event.native.offsetY;
        const tolerance = 10;
        let nearestIndex = null;
        let minDistance = Infinity;

        chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chartInstance.getDatasetMeta(datasetIndex);
            const data = meta.data;
            for (let i = 0; i < data.length - 1; i++) {
                const distance = distanceToSegment(
                    mouseX, mouseY,
                    data[i].x, data[i].y,
                    data[i + 1].x, data[i + 1].y
                );
                if (distance < minDistance && distance < tolerance) {
                    minDistance = distance;
                    nearestIndex = datasetIndex;
                }
            }
        });

        setHoveredDatasetIndex(nearestIndex);
    };


    const options = {
        responsive: true,
        animation: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    padding: 15,
                    boxWidth: 8,
                    boxHeight: 8,
                },
                onClick: (e, legendItem) => {
                    setHoveredDatasetIndex(null)
                    toggleDatasetSelection(legendItem.datasetIndex);
                },
                onHover: (event, legendItem) => {
                    setHoveredDatasetIndex(legendItem.datasetIndex);
                },
                onLeave: (event, legendItem) => {
                    setHoveredDatasetIndex(null);
                },
            },
            title: {
                display: true,
                text: metric?.graphName,
                font: {
                    size: 17,
                    color: "#7A8596"
                },
            },
            tooltip: {
                mode: "index",
                intersect: false,
                filter: function (tooltipItem) {
                    // Ne montrer que les tooltips pour des valeurs non nulles
                    return tooltipItem.raw !== 0;
                },
                position: 'nearest',
                titleFont: {
                    size: 16, // Taille du texte du titre
                    fontWeight: 'semibold'

                },
                bodyFont: {
                    size: 14, // Taille du texte du corps
                    fontWeight: 'bold'
                },

                footerFont: {
                    size: 14, // Taille du texte du pied de page
                    fontWeight: 'normal'
                },
            },
            crosshair: {
                line: {
                    color: '#d3d3d3',
                    width: 1,
                    dashPattern: [5, 5]
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: false,
                },
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10, // Ajustez ce nombre en fonction de la largeur disponible
                }
            },
            y: {
                grid: {
                    display: true,
                },
                ticks: {

                    precision: 0, // Force ticks to be integers

                }
            },
        },

        elements: {
            point: {
                radius: 0,  // Taille du point
                hoverRadius: 0,  // Taille du point au survol
            },
        },
        onClick: handleClickOnChart,
        onHover: handleHover,
    };

    useEffect(() => {

        if (selectedDataSets.length === 0) {
            fillData();
        }
        const chartInstance = chartRef.current;
        if (chartInstance) {
            chartInstance.data.datasets.forEach((dataset, idx) => {
                let color;
                if (hoveredDatasetIndex === idx) { // Explicitement comparé à l'index
                    color = colors[idx % colors.length];
                } else if (selectedDataSets.includes(idx) && hoveredDatasetIndex === null) {
                    color = colors[idx % colors.length];
                } else if (selectedDataSets.includes(idx) && hoveredDatasetIndex !== null && selectedDataSets.length !== metric.datasets.length) {
                    color = adjustOpacity(colors[idx % colors.length], 0.5);
                } else {
                    color = adjustOpacity(colors[idx % colors.length], 0.1);
                }
                dataset.borderColor = color;
                dataset.backgroundColor = color;
            });
            chartInstance.update();
        }
    }, [selectedDataSets, metric.datasets, hoveredDatasetIndex]);


    const refreshChart = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        const handleResize = () => {
            refreshChart();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const data = {
        labels: metric?.labels?.map(date => moment(date, "YYYYMMDD").format("D MMM")),
        datasets: metric.datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            borderColor: colors[index % colors.length], // This will be overridden by useEffect
            backgroundColor: colors[index % colors.length],
            pointRadius: 0,
            pointHoverRadius: 0,
        })),
    };

    return (
        <Line key={refreshKey} ref={chartRef} options={options} data={data} plugins={[{
            beforeDraw: (chart) => {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const ctx = chart.ctx;
                    const x = chart.tooltip._active[0].element.x;
                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.setLineDash([5, 5]);
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#d3d3d3';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        }]}/>
    );
};

export default MultiLineChart;
