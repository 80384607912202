import "./style.css"
import {Publication} from "../Publication";
import React from "react";
import {Icon} from "../../../../Icons/Icon";
import {useSelector} from "react-redux";

export const Carousel = ({onClick, startIndex, publications, selectedTrainingData}) => {

    const [index, setIndex] = React.useState(startIndex ?? 0)
    const sideBar = useSelector(state => state.app.activeNavBar);
    const publication = publications[index]

    const handleLeft = (e) => {
        e.stopPropagation();
        if (index === 0) {
            setIndex(publications.length - 1);
            return;
        }
        setIndex(index - 1)
    }

    const handleRight = (e) => {
        e.stopPropagation();
        if (index === publications.length - 1) {
            setIndex(0);
            return;
        }
        setIndex(index + 1)
    }
    return (
        <div className={`carousel ${sideBar ? "side" : ""}`} onClick={onClick}>
            <button onClick={(e) => handleLeft(e)}>
                <Icon name={"chevron-left"} size={30}/>
            </button>

            <div className={"carousel-content"}>
                <React.Fragment key={publication + "-" + index}>
                    <Publication publication={publication} key={publication}
                                 selectedTrainingData={selectedTrainingData}
                                 onClick={(e) => {
                                     e.stopPropagation()
                                 }}
                    />
                </React.Fragment>
            </div>
            <button onClick={(e) => handleRight(e)}>
                <Icon name={"chevron-right"} size={30}/>
            </button>

        </div>
    )

}