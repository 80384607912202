import {useMutation} from "@tanstack/react-query";
import {metricsRoute} from "../utils/APIRoutes";
import {useDispatch} from "react-redux";
import {setGlobals, setLoaded, setPublicationsByTraining, setRanks, setSimilars, setStrengths} from "../Models/metrics";
import {apiClient} from "./clients";


const fetchGlobals = async ({from, to}) => {
    try {
        const params = new URLSearchParams({from, to}).toString();
        const response = await apiClient.get(`${metricsRoute}?${params}`);
        return response.data;
    } catch (e) {
        throw e
    }

};


const fetchRanks = async () => {
    try {
        const response = await apiClient.get(`${metricsRoute}ranks`);
        return response.data;
    } catch (e) {
        throw e
    }

};


const fetchPublications = async ({trainingId, from, to, skip}) => {
    try {
        const params = new URLSearchParams({trainingId, from, to, skip}).toString();

        const response = await apiClient.get(`${metricsRoute}publications?${params}`);
        return response.data;
    } catch (e) {
        throw e
    }

};

const fetchStrengths = async ({from, to}) => {
    try {
        const params = new URLSearchParams({from, to}).toString();
        const response = await apiClient.get(`${metricsRoute}strengths?${params}`);
        return response.data;
    } catch (e) {
        throw e
    }

};

const fetchSimilars = async (type) => {
    try {
        const response = await apiClient.get(`${metricsRoute}similars/${type}`);
        return response.data;
    } catch (e) {
        throw e
    }


}


const statsService = {
    useFetchGlobal() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchGlobals,
            onSuccess: async (data) => {
                dispatch(setGlobals(data));
                dispatch(setLoaded());
            },
            onError: (error) => {
                dispatch(setLoaded());
            }

        });
    },

    useRanks() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchRanks,
            onSuccess: async (data) => {
                dispatch(setRanks(data));
            },

        });
    },
    usePublications() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchPublications,
            onSuccess: async (data) => {
                dispatch(setPublicationsByTraining(data));
            },

        });
    },

    useStrengths() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchStrengths,
            onSuccess: async (data) => {
                dispatch(setStrengths(data));
            },

        });
    },

    useSimilars() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchSimilars,
            onSuccess: async (data) => {
                dispatch(setSimilars(data));
            },

        });
    }
}

export default statsService;
