import {motion, useInView} from "framer-motion";
import {useRef} from "react";
import "./style.css"
import cardSponso from "../../../pages/Landing/icons/cardSponso.svg";
import rankingSponso from "../../../pages/Landing/icons/rankingsponso.svg";
import {Link} from "react-router-dom";

export const Sponso = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});


    return (
        <section id={"section8"}>
            <div className={"row2"} style={{gap: 80}} ref={ref}>
                <motion.div
                    initial={{opacity: 0, x: "-100%"}}
                    transition={{ease: "easeOut", duration: 0.6}}
                    animate={isInView ? {opacity: 1, x: 0} : {}}
                    className={"border2"}
                ></motion.div>
                <motion.h1
                    initial={{opacity: 0, x: 100}}
                    transition={{ease: "easeOut", duration: 0.6}}
                    animate={isInView ? {opacity: 1, x: 0} : {}}
                    style={{marginRight: "5%", textAlign: "right"}}

                >
                    Faites vous connaître avec<br/> le contenu sponsorisé
                </motion.h1>

            </div>
            <div className={"content"}>
                <div className={"row2"}>
                    <img src={cardSponso} alt={"carte sponsorisé"}/>
                    <h4>
                        Gagnez en visibilité avec les cartes sponsorisés
                    </h4>
                </div>
                <div className={"row2"}>
                    <div>
                        <h4>
                            Apparaissez en haut du classement en tant qu’annonce
                        </h4>

                        <Link
                            to={
                                "/register"
                            }
                            className={"landing-btn register"}
                            style={{display: "block", marginTop: 20}}
                        >
                            Demander un devis
                        </Link>
                    </div>

                    <img src={rankingSponso} alt={"carte sponsorisé"}/>

                </div>

            </div>

        </section>)
}