import React from "react";
import {useSelector} from "react-redux";

import "./style.css"
import {TrainingInRow} from "../Client/Trainings/TrainingInRow";
import {StructureInRow} from "../Client/Structure/StructureInRow";

export const Etablissement = () => {
    const client = useSelector(state => state.client);

    const trainings = useSelector(state => state.trainings.content);

    return <div className={"etablissement structure"}>
        <StructureInRow structure={client} trainings={trainings}/>

        <div style={{marginTop: 30}}>
            <h2>Mes pages sur l’application Hopteo</h2>

            <div className={"trainings-container"}>
                {trainings.map((training, index) => {
                    return <TrainingInRow training={training} index={index} key={training.trainingId}/>
                })}
            </div>
        </div>


    </div>


}