import "./style.css"
import {Icon} from "../../../../Icons/Icon";

export const Card = ({training}) => {
    return (
        <div className={"card  hover"}>
            <div>
                <h4>{training.structureName}</h4>
                <p>{training.trainingLabel}</p>
                <div className={"row"}>
                    <span className={"row2"}><Icon name={"hat"} type="filled" size={24}/>{training.cursus}</span>
                    <span className={"row2"}><Icon name={"location"} size={24}/>{training.localisation}</span>
                </div>
            </div>
        </div>
    )
}