import leadsService from "../../../Services/leadsService";
import {CircularLoader} from "../../LoadingComponents/CircularLoader";

export const UnlockLeadsBtn = ({styles}) => {

    const service = leadsService.useUnlock();

    return (
        <div style={styles}>
            <button
                className={"row2"}
                onClick={async () => await service.mutateAsync()}
                disabled={service.isPending}
                style={{
                    borderColor: "var(--orange)",
                    color: "var(--orange)"
                }}>{service?.isPending ? <><CircularLoader/> Chargement...</> : "Obtenir des leads ultra qualifiées"}
            </button>

        </div>
    );
}
