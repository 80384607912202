import {useForm} from 'react-hook-form';
import adminService from "../../../Services/adminService";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import {useState} from "react";

// Définition de la date par défaut à un an à partir de maintenant
const defaultEndDate = moment().add(1, 'years').subtract(1, 'days').toDate();

// Montant par défaut
const defaultAmount = 100;  // Par exemple, 100 euros

// Création du schéma de validation avec yup
const schema = yup.object().shape({
    endDate: yup.date()
        .required("La date est obligatoire"),
    amount: yup.number()
        .required("Le montant est obligatoire")
        .positive("Le montant doit être positif"),
    studentsCount: yup.number()
        .required("Le nombre d'étudiants est obligatoire")
        .positive("Le nombre d'étudiants doit être positif"),
    frequence: yup.string()
        .required("La fréquence est obligatoire")
        .oneOf(["month", "year"], "Fréquence invalide"),
    status: yup.string()
        .required("Le statut est obligatoire")
        .oneOf(["pending", "active", "expired", "canceled"], "Statut invalide"),
});


export const UpgradeTrainingForm = ({training, handleSuccess, subscription}) => {
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            endDate: subscription?.endDate?.slice(0, 10) || defaultEndDate.toISOString().slice(0, 10), // YYYY-MM-DD format
            amount: subscription?.price || defaultAmount,
            frequence: subscription?.frequence || "year",
            status: subscription?.status || "pending",
            studentsCount: subscription?.studentsCount || 0,
        }
    });
    const service = adminService.useUpgradeTraining();
    const [history, setHistory] = useState(subscription?.history || []);

    const onSubmit = async (data) => {
        try {

            await service.mutateAsync({
                trainingId: training.trainingId,
                endDate: new Date(data.endDate), // Convertir en objet Date
                amount: data.amount,
                frequence: data.frequence,
                status: data.status,
                studentsCount: data.studentsCount,
                subscriptionId: subscription._id,
                history: history
            });

            if (subscription.paymentMode === "card" && subscription.status === "active" && data.status !== "active") {
                handleSuccess(undefined)
            } else {
                handleSuccess(
                    {
                        trainingId: training.trainingId,
                        endDate: new Date(data.endDate).toISOString().slice(0, 10), // Convertir en chaîne de caractères
                        price: data.amount,
                        paymentMode: subscription.paymentMode,
                        studentsCount: data.studentsCount,
                        frequence: data.frequence,
                        status: subscription.paymentMode === "card" && data.status === "active" && subscription.status !== "active" ? "waiting" : data.status,
                        _id: subscription._id,
                        history: history
                    })
            }


        } catch (e) {
            console.error("Error during the upgrade process:", e);
        }
    };

    const addPayment = () => {
        const newPayment = {
            amount: parseFloat(document.getElementById('paymentAmount').value),
            date: document.getElementById('paymentDate').value
        };
        setHistory([newPayment, ...history]);
    };

    const removePayment = (index) => {
        setHistory(history.filter((_, i) => i !== index));
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>

                <div>
                    <div className={"row2"}>
                        <label htmlFor={"endDate"}>Rentrer la date d'expiration de l'abonnement :</label>
                        <input
                            type={"date"}
                            id={"endDate"}
                            {...register("endDate")}
                        />
                    </div>
                    {errors.endDate && <span className="error-static">{errors.endDate.message}</span>}
                </div>


                {subscription.paymentMode === "card" && subscription.status === "active" ? <></> :
                    <div>
                        <div className={"row2"}>
                            <label htmlFor={"frequence"}>Rentrer la fréquence des paiements :</label>
                            <select
                                id={"frequence"}
                                className={"hover"}
                                style={{width: 150}}
                                {...register("frequence")}
                            >
                                <option value="month">Mensuelle</option>
                                <option value="year">Annuelle</option>
                            </select>
                        </div>
                        {errors.frequence && <span className="error-static">{errors.frequence.message}</span>}
                    </div>
                }


                <div>
                    <div className={"row2"}>
                        <label htmlFor={"studentsCount"}>Nombres d'étudiants :</label>
                        <input
                            type={"number"}
                            id={"studentsCount"}
                            {...register("studentsCount")}
                        />
                    </div>
                    {errors.studentsCount && <span className="error-static">{errors.studentsCount.message}</span>}
                </div>


                {subscription.paymentMode === "card" && subscription.status === "active" ? <></> :

                    <div>
                        <div className={"row2"}>
                            <label htmlFor={"amount"}>Montant en € :</label>
                            <input
                                type={"number"}
                                id={"amount"}
                                {...register("amount")}
                            />
                        </div>
                        {errors.amount && <span className="error-static">{errors.amount.message}</span>}
                    </div>
                }
                <div>
                    <div className={"row2"}>
                        <label htmlFor={"status"}>Statut :</label>
                        <select
                            id={"status"}
                            className={"hover"}
                            style={{width: 150}}
                            {...register("status")}
                        >

                            {subscription.paymentMode === "card" && subscription.status !== "pending" ? <>
                                <option value="active">Actif</option>
                                <option value="expired">Expiré</option>
                                <option value="canceled">Annulé</option>
                            </> : subscription.paymentMode === "card" && subscription.status !== "active" ? <>
                                    <option value="pending">En attente</option>
                                    <option value="active">Actif</option>
                                    <option value="canceled">Annulé</option>
                                </> :
                                <>
                                    <option value="pending">En attente</option>
                                    <option value="active">Actif</option>
                                    <option value="expired">Expiré</option>
                                    <option value="canceled">Annulé</option>
                                </>}

                        </select>
                    </div>
                    <div style={{marginTop: 40, paddingBottom: 30}}>
                        <div className={"row"} style={{width: "100%", alignItems: "center", marginBottom: 20}}>
                            <h3>Historique des paiements</h3>
                        </div>
                        <div className={"row"} style={{width: "100%"}}>
                            <div className={"row2"}>
                                <div className={"row2"}>
                                    <label>Montant en € :</label>
                                    <input type="number" id="paymentAmount"/>
                                </div>
                                <div className={"row2"}>
                                    <label>Date :</label>
                                    <input type="date" id="paymentDate"/>
                                </div>
                            </div>
                            <button type={"button"} onClick={addPayment}>Ajouter paiement</button>

                        </div>

                        <ul>
                            {history.map((payment, index) => (
                                <li key={index} className={"row"}
                                    style={{
                                        marginTop: 10,
                                        borderBottom: "1px solid rgba(0,0,0,0.3)",
                                        alignItems: "center",
                                        paddingBottom: 10
                                    }}>
                                    <span>{payment?.amount} €</span>
                                    <span>Le : {payment?.date}</span>
                                    <button type={"button"} onClick={() => removePayment(index)}>Supprimer</button>
                                </li>
                            ))}
                        </ul>

                    </div>
                    {errors.status && <span className="error-static">{errors.status.message}</span>}
                </div>

                {service.isError &&
                    <span className={"error-static"}>Erreur lors de la mise à jour.</span>}

                <button type="submit"
                        style={{
                            width: "fit-content",
                            backgroundColor: "var(--orange)",
                            color: "white",
                            marginLeft: "auto"
                        }}
                        disabled={service.isPending}>{service.isPending ? "Chargement..." : "Appliquer les changements"}</button>
            </div>
        </form>
    );
}
