

export const InputForm = ({field, error, type = ""}) =>{
    return(
    <div style={{position:"relative", paddingBottom: 20}}>
        <input
            type={type}
            {...field}
        />
         <p className={"error"}>{error?.message}</p>
    </div>);
}