import "./details.css"
import {Visites} from "./Visites/Visites";
import {Filters} from "./Filters/Fitlers";
import {useDispatch, useSelector} from "react-redux";
import {DataSelector} from "../../Client/Trainings/data/DataSelector";
import {setSelectedSubMetric} from "../../../Models/metrics";
import {Ranks} from "./Ranks/Ranks";
import {PubliRow} from "./Publications/PubliRow";
import {PublicationsMetrics} from "./Publications/PublicationsMetrics";
import {Strengths} from "./Strengths/Strengths";
import {Similars} from "./Similars/Similars";

export const Details = ({selectedTraining, loading}) => {


    const metrics = useSelector(state => state.stats.metrics);
    const dispatch = useDispatch();

    const handleSelectedType = (selectedType) => {
        dispatch(setSelectedSubMetric(selectedType));
    }

    const renderData = () => {
        switch (metrics.selectedMetric) {
            case "visits":
                return <Visites selectedTraining={selectedTraining}/>
            case "ranks":
                return <Ranks selectedTraining={selectedTraining}/>
            case "publications":
                return <PublicationsMetrics selectedTraining={selectedTraining} loading={loading}/>
            case "strengths":
                return <Strengths selectedTraining={selectedTraining}/>
            case "similars":
                return <Similars selectedTraining={selectedTraining}/>
            default:
                return <div style={{width: "100%", marginTop: 70, justifyContent: "center", display: "flex"}}>
                    <h2>En développement !</h2>
                </div>
        }
    }

    return (
        <div style={{marginTop: 50}}>
            <h3>Analyse détaillée des données</h3>


            <div className={"title"} style={{marginTop: 10, marginBottom: 30}}>
                <DataSelector selectedType={metrics.selectedMetric} setSelectedType={handleSelectedType}
                              data={metrics.options}/>
            </div>

            {metrics.selectedMetric === "publications" &&

                <div style={{marginTop: 10, width: "100%", overflow: "scroll", paddingBottom: 10}}><PubliRow
                    selectedTraining={selectedTraining}/></div>}


            <Filters selectedTraining={selectedTraining}/>

            {renderData()}
        </div>)
}