import {useMutation, useQuery} from "@tanstack/react-query";
import {useDispatch} from "react-redux";
import {setPublications, updateTrainingField} from "../Models/trainings";
import {trainingsRoute} from "../utils/APIRoutes";
import {apiClient} from "./clients";


const fetch = async (credentials) => {
    try {
        const response = await apiClient.get(trainingsRoute, credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};

const patch = async (credentials) => {
    try {
        const response = await apiClient.post(trainingsRoute + "/patch", credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }
};

const fetchPublications = async (credentials) => {
    try {
        const response = await apiClient.get(`${trainingsRoute}publications/${credentials.trainingId}`,);
        return response.data;
    } catch (e) {
        console.log(e)
    }
};

const upgrade = async (credentials) => {
    try {
        const response = await apiClient.post(`${trainingsRoute}upgrade`, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }

}

const trainingsService = {
    useFetch(logged) {
        return useQuery({
            queryKey: ['trainings'],
            queryFn: () => fetch(),
            enabled: !!logged,
            staleTime: Infinity
        });
    },

    usePatch() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: patch,
            onSuccess: async (data) => {
                dispatch(updateTrainingField(data));
            }
        });
    },

    usePublications() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: fetchPublications,
            onSuccess: async (data) => {
                dispatch(setPublications(data));
            }
        });
    },

    useUpgradeTraining() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: upgrade,
            onSuccess: async (data) => {
                dispatch(updateTrainingField(data));
            }
        });
    }
}

export default trainingsService;