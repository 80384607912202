import {Icon} from "../../../Icons/Icon";
import {Link} from "react-router-dom";
import React from "react";

export const UpgradeBtn = () => {

    return (<Link className={"hover row2"} to={"/offres"}
                  style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: "0.9rem",
                      background: "var(--background)"
                  }}>
        <Icon name={"diamon"}
              type={"filled"}
              size={18}/> Mettre à
        niveau une page
        Hopteo</Link>)

}