import {useSelector} from "react-redux";
import "./data.css"
import {useState} from "react";
import {DataSelector} from "./DataSelector";
import {Row} from "../../Row";

export const DisplayData = ({selectedTraining}) => {
    const client = useSelector(state => state.client);

    const trainings = useSelector(state => state.trainings.content);
    const selectedTrainingData = trainings.find(training => training.trainingId === selectedTraining);

    const [selectedType, setSelectedType] = useState("current");

    const types = [{key: "current", value: "Données actuelles"}, {
        key: "pending",
        value: "En attente de validation"
    }, {key: "accepted", value: "Modifications validées"}]


    return (

        <>
            <div style={{margin: "20px 0 10px 0px"}}>
                <DataSelector selectedType={selectedType} setSelectedType={setSelectedType} data={types}/>
            </div>
            <div className={"data-container"}>
                <Row label="Acronym de l’école :" value={client.acronym}/>
                <Row label="Nom du diplôme :" value={selectedTrainingData.trainingLabel}/>
                <Row label="Durée de la formation :" value={selectedTrainingData.trainingPeriod}/>
                <Row label="Ville :" value={selectedTrainingData.township}/>
                <Row label="Code postal :" value={selectedTrainingData.postCode}/>
                <Row label="Adresse :" value={selectedTrainingData.address.join(", ")}/>
                <Row label="Niveau de sortie :" value={selectedTrainingData.forOutLevel}/>
                <Row label="Statut de l’école :" value={selectedTrainingData.structureStatus}/>
                <Row label="Hébergement :" value={selectedTrainingData.accommodation}/>
                <Row label="Coût annuel :" value={selectedTrainingData.summaryIndicatorList[0].value}/>
                <Row label="Salaire moyen en sortie :" value={selectedTrainingData.summaryIndicatorList[1].value}/>
                <Row label="Taux d’étudiants étrangers :"
                     value={`${selectedTrainingData.foreignStudentRate || "Non défini"}`}/>
                <Row label="Taux d’enseignants étrangers :"
                     value={`${selectedTrainingData.foreignTeacherRate || "Non défini"}`}/>
                <Row label="Taux d’embauche à l’étranger :"
                     value={`${selectedTrainingData.extraEmployDataList[1].value}`}/>
                <Row label="Langues enseignées :" value={selectedTrainingData.studiedLanguageList.join(", ")}/>
                <Row label="Détail des options de formation :"
                     value={selectedTrainingData.optionDetailList.join(", ")}/>
            </div>
        </>
    )

}

