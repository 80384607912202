import {useMutation} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {fetchReviewsSuccess} from '../Models/avis';
import {apiClient} from "./clients";
import {avisRoute} from "../utils/APIRoutes";

const fetchAvis = async ({skip = 0, max = 10, type, trainingId}) => {
    try {
        const params = new URLSearchParams({skip, max, type, trainingId}).toString();
        const response = await apiClient.get(`${avisRoute}?${params}`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

const avisService = {

    useFetchAvis() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetchAvis,
            onSuccess: (data) => {
                dispatch(fetchReviewsSuccess(data));
            },
        });
    },
};

export default avisService;