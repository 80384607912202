import "./style.css";
import {Link} from "react-router-dom";
import {useRef} from "react";
import {motion, useInView} from "framer-motion";

export const Leads = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: "-100px", once: true});

    return (
        <section id={"section6"}>

            <div>
                <div className={"row2"} style={{gap: 80}}>
                    <h1
                    >
                        Restez au contact des étudiants
                        <br/> avec nos <span>leads ultra qualifiés</span>
                    </h1>
                    <motion.div
                        initial={{opacity: 0, x: "100%"}}
                        transition={{ease: "easeOut", duration: 0.6}}
                        animate={isInView ? {opacity: 1, x: 0} : {}}
                        className={"border"}
                    ></motion.div>
                </div>

                <h4>4 filtres assurent une qualification innédite de nos leads</h4>
                <div className={"grid2x2"}>
                    <Component
                        name={"Votre formation correspond aux attentes de l’étudiant"}
                        index={1}
                    />
                    <Component
                        name={"L’étudiant a ouvert la page d’une de vos formations"}
                        index={2}
                    />
                    <Component
                        name={"L’étudiant a indiqué qu’il aime la formation"}
                        index={3}
                    />
                    <Component
                        name={
                            "L’étudiant a indiqué vouloir être contacté par votre établissement"
                        }
                        index={4}
                    />
                </div>
            </div>
            <Link to={"/register"} className={"landing-btn register"}>
                Recevoir des leads qualifiés
            </Link>
        </section>
    );
};

const Component = ({index, name}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: "-160px", once: true});

    const delay = index % 2 === 0 ? 0.2 : 0;
    return (
        <div ref={ref}>
            <div>
                <p>{name}</p>
                <motion.span
                    initial={{opacity: 0, scale: 0}}
                    exit={{opacity: 0, scale: 0}}
                    transition={{ease: "easeOut", duration: 0.2, delay: delay}}
                    animate={isInView ? {opacity: 1, scale: 1} : {}}
                >
                    {index}
                </motion.span>
            </div>
        </div>
    );
};