import {Images} from "../../../Images/Images";
import "./couverture.css"
import {useSelector} from "react-redux";
import {getImage} from "../../../../utils/APIRoutes";
import filesService from "../../../../Services/filesService";
import {CropperContainer} from "../../Structure/Crop/Crop";
import React, {useState} from "react";
import {checkIfPremium} from "../../utils";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Functions";
import {useNavigate} from "react-router-dom";

export const Couverture = ({selectedTraining}) => {

    const trainings = useSelector(state => state.trainings.content);
    const selectedTrainingData = trainings.find(training => training.trainingId === selectedTraining);
    const [imageSrc, setImageSrc] = useState(null);

    const {mutateAsync, isPending} = filesService.usePatchBandeau();

    const navigate = useNavigate();

    const handleSubmit = async (file) => {
        if (!checkIfPremium(selectedTrainingData)) {
            toast.info("Mettez à niveau votre formation pour faire cette action.", toastOptions);
            navigate("/offres")
            return;
        }

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('trainingId', selectedTrainingData.trainingId);
            formData.append('token', localStorage.getItem('token'));

            try {
                await mutateAsync(formData);
            } catch (error) {
                console.error('Failed to upload file:', error);
            }
        }
    };
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener('load', (e) => setImageSrc(e.target.result));

            reader.readAsDataURL(event.target.files[0]);
            event.target.value = ''; // Ajoutez cette ligne pour réinitialiser l'élément d'entrée
        }
    };

    return (
        <>
            {imageSrc &&
                <CropperContainer imageSrc={imageSrc} setImageSrc={setImageSrc} handleSubmit={handleSubmit}
                                  handleFileChange={handleFileChange} cropSize={{width: 600, height: 120}}/>}

            <div className={`couverture ${isPending ? "loadingbloc" : ""}`}>


                {selectedTrainingData.bandeau &&
                    <Images alt={"bandeau"} src={getImage + selectedTrainingData.bandeau}/>}
                <label htmlFor="couverture">Modifier</label>
                <input type="file" id="couverture" onChange={handleFileChange} name="couverture" accept="image/*"
                       hidden={true}/>

            </div>
        </>

    )

}