import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GraphLoader} from "../../../LoadingComponents/GraphLoader";
import BarChart from "../../Graphs/BarChart";
import {Table} from "../../../Table/Table";
import {aggregateDataByKeywordsAndTraining, getKeywordData, getMultilineChartData, processStrengthData} from "./utils";
import statsService from "../../../../Services/statsService";
import {setSelectedKeyword} from "../../../../Models/metrics";
import PieChart from "../../Graphs/Pie/Pie";
import LineChart from "../../Graphs/Line/LineChart";
import {Column} from "../../Graphs/Column/Column";
import {sortDataDescending} from "../Visites/utils";
import MultiLineChart from "../../Graphs/Line/MultiLineChart";
import {trackEvent2} from "../../../../utils/ga";
import {checkIfPremium, countPages} from "../../../Client/utils";
import {NotPremium} from "../../Graphs/NotPremiums/Pie";
import {Icon} from "../../../Icons/Icon";

export const Strengths = ({selectedTraining}) => {
    const {
        from,
        to,
        strengths: {rows, loading, cardsByTrainings, selectedKeyword}
    } = useSelector(state => state.stats);
    const service = statsService.useStrengths();
    const filters = useSelector((state) => state.stats.filters);

    const search = useSelector(state => state.trainings.content);
    const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
    const {premiums} = countPages(search);
    const isPremium = selectedTraining && checkIfPremium(selectedTrainingData) || (!selectedTraining && premiums > 0);


    const fetch = async () => {
        await service.mutateAsync({from, to});
    }
    useEffect(() => {
        if (loading && from && to && isPremium) {
            fetch();
        }
    }, [from, to, loading]);

    const {labels, data, tableRows, totalLikes} = useMemo(() => {
        if (!rows || rows?.length === 0 || !isPremium) {
            return {labels: [], data: [], tableRows: [], totalLikes: 0}
        }
        const dataToTreat = selectedTraining ? {[selectedTraining]: cardsByTrainings[selectedTraining]} : cardsByTrainings;
        const aggregatedData = aggregateDataByKeywordsAndTraining(rows, dataToTreat, filters);

        if (!aggregatedData) {
            return {labels: [], data: [], tableRows: [], totalLikes: 0}
        }
        return processStrengthData(aggregatedData, selectedTraining);
    }, [rows, selectedTraining, filters]);

    const columns = ["Mot-clé", "Catégorie", "Étudiants", "Taux"];
    const rowsFormat = ["keyword", "category", "students", "percentage"];

    const options = {
        title: tooltipItems => "Catégorie - Mot clé - Étudiants",
        label: tooltipItem => {
            const percentage = ((tooltipItem.raw / totalLikes) * 100).toFixed(2);
            return `${tooltipItem.label} (${percentage}%)`;
        },
    };

    const complements = {
        title: 'Préférence des étudiants pas mots-clés liés à votre établissement',
        titlex: "Catégorie - Mots clé",
        titley: 'Nombre d’étudiants',
        options: options,
        hiddeX: true
    };

    const dispatch = useDispatch();
    const [onTempo, setOnTempo] = useState(undefined);
    const client = useSelector(state => state.client);

    const handleTempo = (type) => {
        setOnTempo(type);

        if (!client.admin) {
            trackEvent2("display_in_time_data", "Metrics", "open", "strengths", {
                structureId: client.structureId,
                acronym: client.acronym,
                type: type
            });
        }
    };

    const onRowClick = (data) => {
        dispatch(setSelectedKeyword(data))
    }

    const {keywordData, studentsCount, taux, filteredRows} = useMemo(() => {
        if (!selectedKeyword || !isPremium) return {};
        const dataToTreat = selectedTraining ? {selectedTraining: cardsByTrainings[selectedTraining]} : cardsByTrainings;
        const result = getKeywordData(rows, selectedKeyword.keyword, dataToTreat, filters, totalLikes, {from, to})
        if (!result) return {keywordData: {}, studentsCount: 0, taux: 0, filteredRows: []};
        return result;
    }, [rows, selectedKeyword, cardsByTrainings, filters]);


    return (
        <div className={`rank-container strength-container  ${isPremium ? "" : "locked-graph"}`}
             style={{marginTop: 20, minHeight: 200, position: "relative"}}>
            {loading && isPremium ? <GraphLoader/> :
                isPremium ? <>
                    <BarChart labels={labels} data={data} complements={complements}/>
                    {!selectedKeyword ?
                        <div className={"hover"}
                             style={{width: "fit-content", marginTop: 15, transition: "border-color 0.2s"}}>
                            <Table columns={columns} rows={tableRows} rowsFormat={rowsFormat} onRowClick={onRowClick}/>
                        </div>
                        :
                        <div style={{marginTop: 15}}>

                            {onTempo ? (
                                    <div className="onTemp">
                                        <h4 className="row2" style={{cursor: "pointer"}}
                                            onClick={() => setOnTempo(undefined)}>
                                            <Icon name={"back"} type={"filled"} size={24}/>Retour
                                        </h4>
                                        <div className="graph" style={{marginTop: 20}}>
                                            {loading && <GraphLoader/>}
                                            {isPremium && <MultiLineChart
                                                metric={getMultilineChartData(onTempo, filteredRows, {from, to})}
                                            />}

                                        </div>
                                    </div>
                                ) :
                                keywordData.hasOwnProperty("region") && (<>
                                    <div className={"row2 onTemp"} style={{alignItems: "baseline"}}>
                                        <h4 className="row2" style={{cursor: "pointer"}}
                                            onClick={() => dispatch(setSelectedKeyword(undefined))}>
                                            <Icon name={"back"} type={"filled"} size={24}/>Retour
                                        </h4>
                                        <div style={{width: "fit-content", height: 120}}>
                                            <Table columns={columns} rows={[{
                                                ...selectedKeyword,
                                                students: studentsCount,
                                                percentage: taux
                                            }]} rowsFormat={rowsFormat}/>
                                        </div>
                                    </div>


                                    <div className="grid-container">
                                        <div className="row2" style={{width: "100%"}}>
                                            <PieChart
                                                metric={keywordData?.region}
                                                loading={loading}
                                                title={`Régions des étudiants qui aiment : ${selectedKeyword.keyword}`}
                                                handleTempo={() => handleTempo("region")}

                                            />
                                            <PieChart
                                                metric={keywordData?.filiere}
                                                loading={loading}
                                                title={`Filières des étudiants qui aiment : ${selectedKeyword.keyword}`}
                                                handleTempo={() => handleTempo("filiere")}

                                            />
                                        </div>
                                        <div className="line-chart graph">
                                            {loading && <GraphLoader/>}
                                            {keywordData?.inTime &&
                                                <LineChart metric={keywordData?.inTime}/>}
                                        </div>
                                        <Column
                                            loading={loading}
                                            label={"Étudiants"}
                                            title={"Étudiants"}
                                            metric={sortDataDescending(keywordData?.localisation)}
                                            handleTempo={() => handleTempo("localisation")}
                                        />
                                    </div>

                                </>)}
                        </div>
                    }
                </> : <NotPremium inTime={false}/>
            }
        </div>
    );
};