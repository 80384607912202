import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    acronym: "",
    email: "",
    profilePicture: "",
    description: "",
    admin: false,
    adminAccount: "",
    logged: false,
    loaded: false,
    selectedTrainingPage: undefined,
};


export const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        setIsLoaded: (state, action) => {
            return {...state, loaded: action.payload};
        },
        setClient: (state, action) => {
            return {...action.payload, logged: true, loaded: true};

        },
        setField: (state, action) => {
            return {...state, [action.payload.field]: action.payload.value};

        },
        setDescription: (state, action) => {
            return {...state, description: action.payload};

        },
        setPdp: (state, action) => {
            return {...state, profilePicture: action.payload};

        },
        setSelectedTrainingPage: (state, action) => {
            return {...state, selectedTrainingPage: action.payload};
        }
    }
});

export const {setSelectedTrainingPage, setField, setClient, setIsLoaded, setDescription, setPdp} = clientSlice.actions;
