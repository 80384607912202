import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    minDate: undefined,
    maxDate: undefined,
    from: undefined,
    to: undefined,
    loading: true,
    selectedTraining: undefined,
    globalsCount: {visits: 0, likes: 0, impressions: 0, websiteOpenings: 0},
    trainings: {},
    metrics: {
        selectedMetric: "visits",
        options: [{key: "visits", value: "Visites"},
            {key: "publications", value: "Publications"},
            {key: "ranks", value: "Rang sur Hopteo"},
            {key: "strengths", value: "Points forts / Points faibles"},
            {key: "similars", value: "Écoles semblables"},
        ]
    },
    visits: {
        loaded: true
    },
    filters: {
        region: {
            label: "Régions",
            options: [],
            search: "",
            placeholder: "Rechercher une région...",
            key: "region"
        },
        filiere: {
            label: "Filières",
            options: [],
            search: "",
            placeholder: "Rechercher une fillière...",
            key: "filiere"
        },
        localisation: {
            label: "Villes",
            options: [],
            search: "",
            placeholder: "Rechercher une ville...",
            key: "localisation"
        },
        likes: {
            disableSearch: true,
            label: "Likes",
            options: [{label: "Avec likes", selected: false}, {label: "Sans likes", selected: false}],
            description: "Les visiteurs de vos page peuvent indiquer qu’ils aiment la page via un like.",
            key: "likes"
        },
        ranks: {
            disableSearch: true,
            label: "Rang",
            options: {min: 0, max: 0},
            current: {min: 0, max: 0},
            description: "Ce filtre correspond au rang des formations dans le classement personnalisé des étudiants.",
            key: "ranks",
            type: "range"
        },
        categories: {
            label: "Catégories",
            options: [],
            search: "",
            placeholder: "Rechercher une catégorie...",
            key: "categories"
        }
    },
    ranks: {
        datasets: {},
        loading: true
    },
    publications: {
        loading: true,
    },
    allPublications: {
        content: {},
        loading: true,
        globalCount: 0
    },
    selectedPublication: undefined,
    strengths: {
        rows: [],
        filters: {},
        cardsByTrainings: {},
        loading: true,
        selectedKeyword: undefined
    },
    similars: {
        closests: {content: {}, loading: true},
        liked: {content: {}, loading: true},
    }

};


export const statsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {
        setGlobals: (state, action) => {
            return {...state, ...action.payload};
        },
        setRanks: (state, action) => {
            return {...state, ranks: action.payload};
        },
        setPublicationsByTraining: (state, action) => {
            const {trainingId, data, globalCount} = action.payload;

            if (!trainingId || trainingId === 'undefined' || trainingId === "null") { // Handles both `undefined` and `'undefined'`
                // Merge new data with existing content
                const newData = {...state.allPublications.content, ...data}
                // Extract keys after the merge to check if we should set the selected publication
                const keys = Object.keys(state.allPublications.content);

                // Set the selected publication only if it was previously unset (i.e., no keys before the update)
                if (keys.length === 0) {
                    const localKey = Object.keys(newData)[0];
                    state.selectedPublication = newData[localKey];
                }
                state.allPublications.loading = false;
                state.allPublications.content = newData;
            } else {
                // Handle specific trainingId
                const key = Object.keys(data)[0];
                state.selectedPublication = data[key];
                state.publications[trainingId] = data;
                state.publications.loading = false;

            }
            state.allPublications.globalCount = globalCount;

        },

        setLoaded: (state, action) => {
            state.loading = false;
        },
        setFromDate(state, action) {
            state.loading = true;
            state.from = action.payload;
            state.strengths.loading = true;
        },
        setToDate(state, action) {
            state.loading = true;
            state.to = action.payload;
            state.strengths.loading = true;
        },
        setSelectedSubMetric: (state, action) => {
            state.metrics.selectedMetric = action.payload;
        },
        setGlobalSelected: (state, action) => {
            const {selectedMetric} = action.payload;

            return {
                ...state,
                selectedMetric: selectedMetric,
            };
        },
        setFilterOptions: (state, action) => {
            const {filterKey, options} = action.payload;
            const filter = state.filters[filterKey];
            if (filter) {
                filter.options = options;
                if (filterKey === "ranks") {
                    filter.current = options;
                }
            }
        },
        setRanksOptions: (state, action) => {
            const {min, max} = action.payload;
            state.filters.ranks.current = {min, max};
        },
        setSelectedPublication: (state, action) => {
            if (action.payload?.trainingId) {
                state.selectedPublication = action.payload.item;
            } else {
                state.selectedPublication = action.payload.item;
            }
        },

        setStrengths: (state, action) => {
            const {rows, filters, cardsByTrainings} = action.payload;
            state.strengths.rows = rows;
            state.strengths.filters = filters;
            state.strengths.cardsByTrainings = cardsByTrainings;
            state.strengths.loading = false;
        },
        setSelectedKeyword: (state, action) => {
            state.strengths.selectedKeyword = action.payload;
        },
        setSelectedTraining: (state, action) => {
            state.selectedTraining = action.payload;
        },
        setSimilars: (state, action) => {
            const {data, type} = action.payload;
            state.similars[type] = {content: data, loading: false};
        },
        setStrengthsCategories: (state, action) => {

            state.filters.categories.options = Array.from(action.payload).map(label => ({label, selected: false}))
        }

    }
});

export const {
    setStrengthsCategories,
    setRanksOptions,
    setSimilars,
    setSelectedTraining,
    setSelectedKeyword,
    setStrengths,
    setPublicationsByTraining,
    setSelectedPublication,
    setRanks,
    setSelectedSubMetric,
    setFilterOptions,
    setLoaded,
    setGlobalSelected,
    setGlobals,
    setFromDate,
    setToDate
} = statsSlice.actions;