import './style.css';
import {useNavigate} from "react-router-dom";

const PricingTable = () => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        const app = document.getElementsByClassName("app-page")[0];
        app.scrollTo({top: 0, behavior: 'smooth'});
    };
    return (

        <table className="pricing-table">
            <thead style={{height: 231}}>
            <tr>
                <th>Démarquez votre établissement et<br/> recruter les meilleurs étudiants</th>
                <th id={"standard"}>
                    <h3>Standard</h3>
                    <br/>
                    <span>Gratuit</span>

                    <button>Actif</button>
                </th>
                <th id={"gold"}>
                    <h3>Gold</h3>
                    <br/>
                    <span>Dès 1 500 €/an par formation</span>

                    <button onClick={() => scrollToTop()}>Mettre à niveau</button>
                </th>
                <th id={"custom"}>
                    <h3>Personnalisée</h3>
                    <br/>
                    <span>Contactez-nous pour obtenir un devis</span>
                    <button onClick={() => navigate("/params/contact")}>Demander un devis</button>

                </th>
            </tr>
            </thead>
            <tbody>

            <tr className="section-header">
                <td>Valorisez vos formations à travers des pages exhaustives</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Apparaissez dans le classement personnalisé des étudiants
                </td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Mettez en avant les informations spécifiques à vos formations</td>
                <td className="center"></td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Avis d'étudiants affichés sur vos pages formations</td>
                <td className="center"></td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr className="section-header">
                <td>Prenez le contrôle de vos pages formation</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Mettez à jour les informations sur vos pages formations</td>
                <td className="center">1 fois par an</td>
                <td className="center">Illimité, validé sous 2 jours</td>
                <td className="center">Illimité, validé sous 2 jours</td>
            </tr>
            <tr>
                <td>Ajoutez des liens vers votre site internet</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Publiez du contenu sur vos pages (photos, flyers, vidéos...)</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Répondez aux questions les plus fréquentes</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Répondez aux avis d'étudiants</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Connectez votre BDE aux potentiels candidats via un chat</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr className="section-header">
                <td>Analysez les tendances des étudiants</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Accédez au nombre de visites de vos pages formations</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Analysez les profils des étudiants qui aiment vos formations</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Mesurez la pertinence de votre offre de formation</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Identifiez les filières de vos visiteurs</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Cartographiez l'origine géographique des étudiants</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr className="section-header">
                <td>Recevez des leads ultra qualifiés, assurés par 5 filtres</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Les étudiants sont issus des filières qui vous intéressent</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Votre formation correspond aux critères de l'étudiant</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>L'étudiant a déjà visité au moins une fois votre page de profil</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>L'étudiant a "liké" votre page de profil</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Le prospect a explicitement indiqué vouloir être recontacté</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
                <td className="center checked">✓</td>
            </tr>
            <tr className="section-header">
                <td>Boostez votre visibilité</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Augmentez le nombre de visites</td>
                <td className="center">—</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Lancez une campagne de promotion ciblée</td>
                <td className="center">—</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
            </tr>
            <tr>
                <td>Démarquez-vous auprès des étudiants</td>
                <td className="center">—</td>
                <td className="center">—</td>
                <td className="center checked">✓</td>
            </tr>
            </tbody>
        </table>

    );
};

export default PricingTable;
