import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setSelectedTraining} from "../../../Models/metrics";
import {Selector} from "../../Client/Trainings/selector/Selector";
import {DateSelector} from "./DateSelector";
import {useLocation} from "react-router-dom";

export const MetricsMainSelector = ({loading}) => {
    const selectedTraining = useSelector(state => state.stats.selectedTraining);
    const dispatch = useDispatch();

    const [hasScrolled, setHasScrolled] = useState(false);  // State to track scroll position
    const location = useLocation(); // Get the current location


    const onSelectedTraining = (training) => {
        if (loading) return;
        dispatch(setSelectedTraining(training));
    }


    useEffect(() => {
        const appPage = document.getElementsByClassName('app-page')[0];

        if (!appPage) return;

        const handleScroll = () => {
            const position = appPage.scrollTop;
            setHasScrolled(position > 50); // Modifiez ce seuil si nécessaire
            if (position > 50 && location.pathname === "/statistiques") {
                document.body.classList.add('hide');
            } else {
                document.body.classList.remove('hide');
            }
        };

        appPage.addEventListener('scroll', handleScroll);

        return () => {
            document.body.classList.remove('hide');
            appPage.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // Only render this component if the current route is '/stats'
    if (location.pathname !== "/statistiques") {
        return null;
    }

    return (<div className={`row fixed-bar ${hasScrolled ? 'scroll-shadow' : ''}`} style={{height: 'fit-content'}}>
        <div className={"row2 selector-color"}>

            <Selector selectedTraining={selectedTraining}
                      setSelectedTraining={onSelectedTraining}
                      all={true}
            />

        </div>
        <DateSelector/>
    </div>)
}