import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setFilterOptions} from "../../../../../Models/metrics";
import {trackEvent2} from "../../../../../utils/ga";

const NormalFilter = ({filter, localOptions, setLocalOptions, setIsOpen, search}) => {
    const dispatch = useDispatch();

    const handleCheckboxChange = (option) => {
        setLocalOptions(prevOptions =>
            prevOptions.map(opt =>
                opt.label === option.label ? {...opt, selected: !opt.selected} : opt
            )
        );
    };

    const client = useSelector(state => state.client);


    const handleSave = () => {
        dispatch(setFilterOptions({filterKey: filter.key, options: localOptions}));
        setIsOpen(false);

        if (!client.admin) {
            trackEvent2("filters_event", "Metrics", "apply", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    };

    const handleClear = () => {
        setLocalOptions(prevOptions =>
            prevOptions.map(opt => ({...opt, selected: false}))
        );
        if (!client.admin) {
            trackEvent2("filters_event", "Metrics", "clear", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    };

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", gap: 10, overflow: "scroll"}}>
                {localOptions
                    .filter(opt => opt.label.toLowerCase().includes(search.toLowerCase()))
                    .map(option => (
                        <div key={option.label} className={"row2"} style={{gap: 30}}>
                            <input
                                type="checkbox"
                                id={option.label}
                                checked={option.selected}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            <label htmlFor={option.label}>{option.label}</label>
                        </div>
                    ))
                }
            </div>

            <div className={"row"}>
                <button onClick={handleClear}>Effacer</button>
                <button onClick={handleSave}>Enregistrer</button>
            </div>
        </>
    );
};

export default NormalFilter;
