import {useSelector} from "react-redux";
import "./publications.css"
import filesService from "../../../../Services/filesService";
import {Publication} from "./Publication";
import React, {useEffect, useState} from "react";
import {CircularLoader} from "../../../LoadingComponents/CircularLoader";
import {Icon} from "../../../Icons/Icon";
import {Carousel} from "./Carousel/Carousel";
import trainingsService from "../../../../Services/trainingsService";
import {trackEvent2} from "../../../../utils/ga";
import {checkIfPremium} from "../../utils";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Functions";
import {useNavigate} from "react-router-dom";

export const Publications = ({selectedTraining}) => {

    const selectedTrainingData = useSelector(state => state.trainings.content.find(training => training.trainingId === selectedTraining));
    const isLoaded = selectedTrainingData.publicationLoaded ?? false;
    const publications = selectedTrainingData.publications ?? [];

    const {mutateAsync, isPending} = filesService.useUpload();

    const [carousel, setCarousel] = useState(-1);
    const navigate = useNavigate();

    const client = useSelector(state => state.client);


    const handleUpload = async (e) => {
        if (!checkIfPremium(selectedTrainingData)) {
            toast.info("Mettez à niveau votre formation pour faire cette action.", toastOptions);
            navigate("/offres")
            return;
        }
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('trainingId', selectedTrainingData.trainingId);
            formData.append('token', localStorage.getItem('token'));

            try {
                await mutateAsync(formData);
            } catch (error) {
                console.error('Failed to upload file:', error);
            }
        }

        if (!client.admin) {
            trackEvent2("create_event", "Publication", "create", "", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    };

    const service = trainingsService.usePublications();

    const fetchPublications = async (trainingId) => {
        try {
            await service.mutateAsync({trainingId: trainingId});
        } catch (error) {
        }
    }

    useEffect(() => {
        if (selectedTraining && !isLoaded && selectedTrainingData) {
            fetchPublications(selectedTrainingData.trainingId);
        }

    }, [selectedTraining, isLoaded, selectedTrainingData]);


    const handleClickPublication = (index) => {
        setCarousel(index)
        if (!client.admin) {
            trackEvent2("toggle_event", "Publication", "open", "carousel", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }
    return (
        <>
            {carousel >= 0 &&
                <Carousel publications={publications} selectedTrainingData={selectedTrainingData} onClick={(e) => {
                    e.stopPropagation();
                    setCarousel(-1)
                }}
                          startIndex={carousel}
                />}
            <div className={"row2"} style={{gap: 50}}>
                <h3>Publications</h3>
                <label htmlFor="file" className={"labelplus"}><Icon name={"add"} type={"filled"} size={"24"}/></label>
                <input type="file" id="file" name="file" onChange={handleUpload} accept="image/*"
                       hidden={true}/>
            </div>

            {service.isPending && <div style={{
                width: "100%",
                margin: 20
            }}><CircularLoader/></div>}
            ·
            <div className={"row2"} style={{marginTop: 15}}>
                <div className={"publications"}>
                    {isPending && <CircularLoader/>}
                    {!service.isPending && publications.map((publication, index) => {

                        return <Publication publication={publication}
                                            key={publication.filename + "" + index}
                                            selectedTrainingData={selectedTrainingData}
                                            onClick={() => handleClickPublication(index)}/>

                    })}
                    {publications.length === 0 && <p>Ajoutez votre première publication à votre page !</p>}
                </div>
            </div>
        </>
    )
}