import {DefaultRoutes} from "./Routes/DefaultRoutes";
import {LoggedRoutes} from "./Routes/LoggedRoutes";
import {useDispatch, useSelector} from "react-redux";
import Header from "./components/navigation/app/Header";
import NavBar from "./components/navigation/app/navbar";
import trainingsService from "./Services/trainingsService";
import LoadingPage from "./components/LoadingComponents/loadingpage/loadingPage";
import {setTrainings} from "./Models/trainings";

export const Wrapper = ({appRef}) => {
    const logged = useSelector(state => state.client.logged);
    const loaded = useSelector(state => state.client.loaded);
    const trainingsLoaded = useSelector(state => state.trainings.loaded); // Check if trainings are already loaded
    const dispatch = useDispatch();

    const {data, isLoading} = trainingsService.useFetch(logged);

    // Only dispatch setTrainings if data is new and not already loaded
    if (!trainingsLoaded && data && !isLoading) {
        dispatch(setTrainings(data));
    }

    if (!loaded || isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {loaded && !logged ? (
                <DefaultRoutes/>
            ) : (
                <div className="app-container">
                    <Header/>
                    <NavBar/>
                    <div className="app-page" ref={appRef}>
                        <LoggedRoutes/>
                    </div>
                </div>
            )}
        </>
    );
};
