import React from "react";
import "./style.css"
import {Closests} from "./Types/Closests";
import {Liked} from "./Types/Liked";
import {useSelector} from "react-redux";
import {checkIfPremium, countPages} from "../../../Client/utils";

export const Similars = ({selectedTraining}) => {


    const search = useSelector(state => state.trainings.content);
    const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
    const {premiums} = countPages(search);
    const isPremium = selectedTraining && checkIfPremium(selectedTrainingData) || (!selectedTraining && premiums > 0);


    return (
        <div className={"similars"}>
            <Closests selectedTraining={selectedTraining} isPremium={isPremium}/>
            <Liked selectedTraining={selectedTraining} isPremium={isPremium}/>
        </div>
    )
}