import {Table} from "../Table/Table";
import {useDispatch, useSelector} from "react-redux";
import leadsService from "../../Services/leadsService";
import {moveToExported, setLeadLoading, setSelected} from "../../Models/leads";
import {UnlockLeadsBtn} from "./UnlockBtn/UnlockLeadsBtn";
import {ExportLeadsBtn} from "./ExportBtn/ExportLeadsBtn";
import {trackEvent2} from "../../utils/ga";

export const TableLeadsDecorator = () => {

    const selected = useSelector(state => state.leads.selected)
    const columns = useSelector(state => state.leads.columns)
    const filters = useSelector(state => state.leads.filters)

    const data = useSelector(state => state.leads[selected])
    const leads = useSelector(state => state.leads)

    const dispatch = useDispatch()

    const service = leadsService.useFetchMore();

    const isAdmin = useSelector(state => state.client.admin)
    const client = useSelector(state => state.client);

    const fetchMore = async () => {
        try {
            dispatch(setLeadLoading({type: selected, loading: true}))
            await service.mutateAsync({skip: data.content.length, max: data.max, type: selected})
        } catch (e) {
            console.error(e)
        }
    }

    const onSelect = (filter) => {
        dispatch(setSelected(filter));

        if (!client.admin) {
            trackEvent2("select_event", "Leads", "select", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }

    const formattedFilters = () => {
        return filters.map((filter) => {
            return {key: filter.key, value: leads[filter.key].total + " " + filter.value}
        })
    }

    const formatted = formattedFilters();
    const columnKeys = getColumnKeys(columns);
    const columnValues = getColumnValues(columns);


    const moveTo = (skip, max, from) => {
        dispatch(moveToExported({skip, max, from}));
    }
    return (
        <>
            {selected === "leads" && !isAdmin && data?.total > 0 &&
                <UnlockLeadsBtn max={data?.total} styles={{marginBottom: 20}}/>}

            {(selected === "unlocked" || selected === "exported") && !isAdmin && data?.total > 0 &&
                <ExportLeadsBtn max={data?.total} styles={{marginBottom: 20}} isAdmin={false} type={selected}
                                moveTo={moveTo}/>}

            <Table columns={columnValues} rows={data?.content} rowsFormat={columnKeys} total={data?.total}
                   loading={data?.loading}
                   filters={formatted}
                   fetchMore={fetchMore} onSelect={onSelect}
                   selected={selected}/>
        </>
    )
}

export const getColumnKeys = (columns) => {
    return columns?.map(column => column.key);
};

export const getColumnValues = (columns) => {
    return columns?.map(column => column.value);
};