import {useMutation} from "@tanstack/react-query";
import {addPublication, editBandeau, editPdp} from "../utils/APIRoutes";
import {useDispatch} from "react-redux";
import {addPublicationMethod, updateTrainingField} from "../Models/trainings";
import {setPdp} from "../Models/client";
import {fileClient} from "./clients";

const uploadPublication = async (credentials) => {
    try {
        const response = await fileClient.post(addPublication, credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};

const patchBandeau = async (credentials) => {
    try {
        const response = await fileClient.post(editBandeau, credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }
};

const patchPdp = async (credentials) => {
    try {
        const response = await fileClient.post(editPdp, credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};


const filesService = {
    useUpload() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: uploadPublication,
            onSuccess: async (data) => {
                dispatch(addPublicationMethod(data))

            }
        });
    },
    usePatchBandeau() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: patchBandeau,
            onSuccess: async (data) => {
                dispatch(updateTrainingField(data));
            }
        });
    },
    usePatchProfilePicture() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: patchPdp,
            onSuccess: async (data) => {
                dispatch(setPdp(data))
            }
        });
    }
}

export default filesService;