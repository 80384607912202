import {FilterSelector} from "../../Table/FilterSelector";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import adminService from "../../../Services/adminService";
import {changeForAll} from "../../../Models/admin";
import {CircularLoader} from "../../LoadingComponents/CircularLoader";
import {CardAvis} from "./CardAvis";

export const Avis = () => {

    const filters = useSelector(state => state.admin.avis.filters);

    const [selected, setSelected] = useState("pending");
    const localToken = localStorage.getItem('tokenCapitaineStudy');
    const [tokenCapitaineStudy, setTokenCapitaineStudy] = useState(localToken);
    const dispatch = useDispatch();

    const onSelect = (filter) => {
        setSelected(filter)
    }

    const avis = useSelector(state => state.admin.avis);

    const formattedFilters = (filters) => {
        return filters.map((filter) => {
            return {key: filter.key, value: filter.value + " (" + avis[filter.key].total + ")"}
        })
    }

    const service = adminService.useAvis();

    const fetchData = async (all = false, reset = false) => {
        await service.mutateAsync({
            skip: reset ? 0 : avis[selected].content.length,
            max: avis.max,
            status: selected,
            forAll: all,
        })
    }

    const forAll = useSelector(state => state.admin.avis.forAll);

    useEffect(() => {
        if (avis[selected].loading && tokenCapitaineStudy) {
            fetchData(forAll)
        }
    }, [selected, tokenCapitaineStudy]);


    const handleChange = async (data) => {
        dispatch(changeForAll(data))
        await fetchData(data, true)
    }

    const {data, isLoading} = adminService.useToken();

    useEffect(() => {
        if (!tokenCapitaineStudy && data) {
            setTokenCapitaineStudy(data.token)
            const now = new Date();
            now.setDate(now.getDate() + 6); // Le token expire dans 6 jours
            localStorage.setItem('tokenCapitaineStudy', data.token);
            localStorage.setItem('capitaineStudyTokenExpiration', now.toISOString());
        }
    }, [data, tokenCapitaineStudy]);
    return (
        <>

            <div>
                <label className={"row2"} style={{gap: 10, width: "fit-content"}}>
                    <input
                        onChange={() => handleChange(!forAll)}
                        type="checkbox"
                        checked={forAll}
                        disabled={service.isPending}
                    />
                    Afficher pour toutes les écoles
                </label>

                <div style={{marginTop: 40}}>
                    {filters &&
                        <FilterSelector setFilter={onSelect} filters={formattedFilters(filters)} selected={selected}
                                        disabled={service.isPending}/>}

                    <div className={"avis-container"} style={{marginTop: 8}}>
                        {avis[selected].content.map((item, index) => {
                            return <CardAvis avis={item} key={item._id} tokenCapitaineStudy={tokenCapitaineStudy}/>
                        })}
                    </div>


                    {avis[selected].content.length > 0 && avis[selected].content.length < avis[selected].total && (
                        <button onClick={fetchData} className={"btntab"}>
                            {service.isPending ?
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CircularLoader height={50} width={50}/>
                                </div> : "Voir plus"}
                        </button>
                    )}
                    {avis[selected].loading &&
                        <div style={{height: 300, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <CircularLoader width={70} height={70}/>
                        </div>
                    }

                    {avis[selected].content.length === 0 && !avis[selected].loading &&
                        <p>Pas d'avis pour le moment.</p>}
                </div>


            </div>
        </>

    )
}