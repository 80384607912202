import {ThreeCircles} from "react-loader-spinner";


export const CircularLoader = ({width = 30, height = 30}) => {
    return (

        <ThreeCircles
            height={height}
            width={width}
            radius="30"
            color="var(--orange)"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName="fetchloader"
            visible={true}
        />

    );
}