import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    selectedFilter: "clients",
    filters: [
        {key: "clients", value: "Gestion des clients"},
        {key: "avis", value: "Avis"},
        {key: "leads", value: "Leads"},
    ],
    clients: {
        selectedStructure: undefined,
        actives: {
            total: 0,
            content: [],
            loading: true,
            columns: [{key: "acronym", value: "Nom"}, {key: "trainings", value: "Pages formations"}, {
                key: "premiums",
                value: "Pages premium"
            }, {key: "payment", value: "Dernier dernier payment"}]
        },
        pendings: {
            total: 0,
            content: [],
            loading: true,
            columns: [
                {key: "acronym", value: "Nom"},
                {key: "email", value: "Email"},
                {key: 'request.phone', value: "Téléphone"},
                {key: 'request.fileId', value: "Justificatif"},
                {key: 'createdAt', value: "Demande le"},
            ]
        },
        max: 15,
        filters: [{key: "actives", value: "Liste des clients"}, {
            key: "pendings",
            value: "Demandes d’inscription en attente"
        }],
        selected: "actives",
        loaded: false,
    },
    avis: {
        pending: {total: 0, content: [], loading: true},
        refused: {total: 0, content: [], loading: true},
        verified: {total: 0, content: [], loading: true},
        max: 15,
        filters: [
            {key: "pending", value: "À modérer"},
            {
                key: "refused",
                value: "Refusés"
            },
            {
                key: "verified",
                value: "Vérifiés"
            }],
        forAll: false,
        loaded: false
    },
    leads: {
        leads: {content: [], max: 15, total: 0, loading: true},
        unlocked: {content: [], max: 15, total: 0, loading: true},
        exported: {content: [], max: 15, total: 0, loading: true},
        structuresByLeads: {content: [], max: 15, total: 0, loading: true},
        filters: [
            {key: "leads", value: "Leads disponibles"},
            {key: "unlocked", value: "Leads débloqués"},
            {key: "exported", value: "Leads exportés"}
        ],
        columns: [
            {key: "email", value: "Email"},
            {value: "Filière", key: "filiere"},
            {value: "Année", key: "studyYear"},
            {value: "Rang", key: "rank"},
            {value: "Pages", key: "trainingIds"}],
        loaded: false,
        forAll: true,
        selected: "leads",
        selectedStructure: undefined
    }
};

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setSelectedFilter: (state, action) => {
            return {...state, selectedFilter: action.payload};
        },
        setSelectedSubFilter: (state, action) => {
            return {...state, [state.selectedFilter]: {...state[state.selectedFilter], selected: action.payload}};
        },
        setSubFilterLoading: (state, action) => {
            const {selectedFilter, selected, loading} = action.payload;
            state[selectedFilter][selected].loading = loading;
        },
        setAdminData: (state, action) => {
            const {content, type} = action.payload;
            if (!type) {
                // sort le resultat et mettre dans le bon subfilter
                state.clients.actives.content = content.actives.content;
                state.clients.actives.total = content.actives.total;
                state.clients.pendings.content = content.pendings.content;
                state.clients.pendings.total = content.pendings.total;
                state.clients.loaded = true;
                state.clients.pendings.loading = false;
                state.clients.actives.loading = false;
            } else {
                state.clients[type].content = [...state.clients[type].content, ...content.content];
                state.clients[type].total = content.total
                state.clients[type].loading = false;
            }
        },
        setAvis: (state, action) => {
            const {pendingCount, refusedCount, verifiedCount, content, type} = action.payload;
            state.avis.pending.total = pendingCount;
            state.avis.refused.total = refusedCount;
            state.avis.verified.total = verifiedCount;
            state.avis[type].content = [...state.avis[type].content, ...content];
            state.avis[type].loading = false;
        },
        setLeads: (state, action) => {
            const {
                leads,
                soldCount,
                lockedCount,
                exportedCount, type
            } = action.payload;
            state.leads.unlocked.total = soldCount;
            state.leads.exported.total = exportedCount;
            state.leads.leads.total = lockedCount;
            state.leads[type].content = [...state.leads[type].content, ...leads];
            state.leads[type].loading = false;
        },
        addClient(state, action) {
            state.clients.actives.content.unshift(action.payload);
            const index = state.clients.pendings.content.findIndex(client => client._id === action.payload._id);
            if (index !== -1) {
                const client = state.clients.pendings.content.splice(index, 1)[0];
                state.clients.pendings.total -= 1;
            }
            state.clients.actives.total += 1;
        },
        changeForAll: (state, action) => {
            state.avis = {...initialState.avis, forAll: action.payload};
        },
        changeForAllLeads: (state, action) => {
            state.leads = {...initialState.leads, forAll: action.payload};
        },
        updateAvisStatus(state, action) {
            const {avisId, newStatus} = action.payload;
            const statuses = ["pending", "refused", "verified"];
            let avis;

            // Find and remove the avis from its current list
            statuses.forEach(status => {
                const index = state.avis[status].content.findIndex(item => item._id === avisId);
                if (index !== -1) {
                    avis = state.avis[status].content.splice(index, 1)[0];
                    state.avis[status].total -= 1;
                }
            });

            if (avis) {
                // Update the status of the avis
                avis.status = newStatus;
                // Add the avis to the new list
                state.avis[newStatus].content.unshift(avis);
                state.avis[newStatus].total += 1;
            }
        },
        moveToExported(state, action) {
            const {skip, max, from} = action.payload;
            const leadsToMove = state.leads[from].content.slice(skip, skip + max);
            state.leads.exported.content = [...leadsToMove, ...state.leads.exported.content];
            state.leads.exported.total += leadsToMove.length;

            // Remove leads from the original list
            state.leads[from].content = state.leads[from].content.filter((_, index) => index < skip || index >= skip + max);
            state.leads[from].total -= leadsToMove.length;
        },
        setSelectedStructure: (state, action) => {
            state.clients.selectedStructure = action.payload;
        },
        setStructuresByLeads: (state, action) => {
            const {content} = action.payload;
            state.leads.structuresByLeads.content = [...state.leads.structuresByLeads.content, ...content];
            state.leads.structuresByLeads.loading = false;
        },
        setSelectedStructureLeads: (state, action) => {
            state.leads.selectedStructure = action.payload;
            state.leads.leads = {content: [], max: 15, total: 0, loading: true}
            state.leads.unlocked = {content: [], max: 15, total: 0, loading: true}
            state.leads.exported = {content: [], max: 15, total: 0, loading: true}

        }
    }
});

export const {
    setSelectedStructureLeads,
    setStructuresByLeads,
    moveToExported,
    setSelectedStructure,
    changeForAllLeads,
    setLeads,
    changeForAll,
    setAvis,
    addClient,
    setSubFilterLoading,
    setAdminData,
    setSelectedSubFilter,
    setSelectedFilter,
    updateAvisStatus
} = adminSlice.actions;
