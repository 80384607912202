import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    leads: {content: [], max: 15, total: 0, loading: false},
    unlocked: {content: [], max: 15, total: 0, loading: false},
    exported: {content: [], max: 15, total: 0, loading: false},
    columns: [
        {key: "email", value: "Email"},
        {value: "Filière", key: "filiere"},
        {value: "Année", key: "studyYear"},
        {value: "Rang", key: "rank"},
        {value: "Pages", key: "trainingIds"}],
    filters: [
        {key: "leads", value: "leads disponibles"},
        {key: "unlocked", value: "leads débloqués"},
        {key: "exported", value: "leads exportés"}
    ],
    loaded: false,
    selected: "leads"
};


export const leadsSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
        setLeads: (state, action) => {
            state.leads = {...state.leads, ...action.payload.leads};
            state.unlocked = {...state.unlocked, ...action.payload.unlocked};
            state.exported = {...state.exported, ...action.payload.exported};
            state.loaded = true;
        },
        setMore: (state, action) => {
            state[action.payload.type].content = [...state[action.payload.type].content, ...action.payload.leads.content]
            state[action.payload.type].total = action.payload.leads.total
            state[action.payload.type].loading = false;
        },
        setLeadLoading: (state, action) => {
            state[action.payload.type].loading = action.payload.loading;
        },
        setSelected: (state, action) => {
            state.selected = action.payload;
        },
        moveToExported(state, action) {
            const {skip, max, from} = action.payload;
            const leadsToMove = state[from].content.slice(skip, skip + max);
            state.exported.content = [...leadsToMove, ...state.exported.content];
            state.exported.total += leadsToMove.length;

            // Remove leads from the original list
            state[from].content = state[from].content.filter((_, index) => index < skip || index >= skip + max);
            state[from].total -= leadsToMove.length;
        },

    }
});

export const {moveToExported, setMore, setLeadLoading, setLeads, setSelected} = leadsSlice.actions;
