import axios from "axios";
import {apiClient} from "./clients";
import {adminRoute} from "../utils/APIRoutes";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useDispatch} from "react-redux";
import {addClient, setAdminData, setAvis, setLeads, setStructuresByLeads, updateAvisStatus} from "../Models/admin";

const fetch = async (credentials) => {
    try {
        const response = await apiClient.get(adminRoute, credentials);
        return response.data;
    } catch (e) {
        console.log(e)
    }

}

const create = async (credentials) => {
    try {
        const response = await apiClient.post(adminRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
};


const structures = async ({search}) => {
    try {
        const params = new URLSearchParams({search}).toString();

        const response = await apiClient.get(`${adminRoute}/structures?${params}`);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};


const avis = async ({skip, max, status, forAll}) => {
    try {
        const params = new URLSearchParams({skip, max, status, forAll}).toString();
        const response = await apiClient.get(`${adminRoute}/avis?${params}`);
        return response.data;
    } catch (e) {
        throw e
    }

};

const leads = async ({skip, max, type, forAll, structure}) => {
    try {
        const params = new URLSearchParams({skip, max, type, forAll, structure}).toString();

        const response = await apiClient.get(`${adminRoute}/leads?${params}`);
        return response.data;
    } catch (e) {
        throw e
    }

};


const moderation = async (credentials) => {
    try {

        const response = await apiClient.post(`${adminRoute}/moderation/patch`, credentials);
        return response.data;
    } catch (e) {
        throw e
    }

};

const structure = async (credentials) => {
    try {
        const response = await apiClient.post(`${adminRoute}/structure`, credentials);
        return response.data;
    } catch (e) {
        throw e;
    }
};


const structureDetails = async (credentials) => {
    try {
        const response = await apiClient.get(`${adminRoute}/structure/${credentials.structureId}`);
        return response.data;
    } catch (e) {
        throw e;
    }

}


const upgradeTraining = async (credentials) => {
    try {
        const response = await apiClient.post(`${adminRoute}/upgrade`, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
}

const fetchToken = async () => {
    try {
        const response = await axios.post(`https://www.capitainestudy.fr/wp-json/jwt-auth/v1/token`, {
            username: "omer.boutillier",
            password: "e6wxKHWVLtkaD2Dyb4ZmwYoI"
        });
        return response.data;
    } catch (e) {
        throw e;
    }

}


const structuresByLeads = async (credentials) => {
    try {
        const params = new URLSearchParams({skip: credentials.skip}).toString()
        const response = await apiClient.get(`${adminRoute}/leads/structureByLeads?${params}`);
        return response.data;
    } catch (e) {
        throw e;
    }
}

const adminService = {
    useFetch() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: fetch,
            onSuccess: async (data) => {
                dispatch(setAdminData(data))
            },

        });
    },

    useCreate() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: create,
            onSuccess: async (data) => {
                dispatch(addClient(data))
            }
        });
    },
    useStructures() {

        return useMutation({
            mutationFn: structures,
            onSuccess: async (data) => {
                return data;
            }
        });
    },

    useAvis() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: avis,
            onSuccess: async (data) => {
                dispatch(setAvis(data))
            }
        });
    },

    useLeads() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: leads,
            onSuccess: async (data) => {
                dispatch(setLeads(data))
            }
        });
    },

    useModeration() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: moderation,
            onSuccess: async (data) => {
                dispatch(updateAvisStatus(data))
            }
        });
    },

    useStructure() {
        return useMutation({
            mutationFn: structure,
            onSuccess: async (data) => {
                if (window.location.pathname === "/") {
                    window.location.reload();
                } else {
                    window.location.replace("/")
                }
            }
        });
    },

    useFetchStructureDetails(selectedStructure) {
        return useQuery({
            queryKey: ['structureDetails', selectedStructure],
            queryFn: () => structureDetails({structureId: selectedStructure}),
            enabled: !!selectedStructure,
            staleTime: Infinity,
        });
    },

    useUpgradeTraining() {
        return useMutation({
            mutationFn: upgradeTraining,
        });
    },
    useToken() {
        return useQuery({
            queryKey: ['capitaineStudyToken'],
            queryFn: fetchToken,
            // Cette option détermine quand effectuer la requête basée sur le token existant et son expiration
            enabled: (() => {
                const tokenExpiration = localStorage.getItem('capitaineStudyTokenExpiration');
                return !tokenExpiration || new Date() > new Date(tokenExpiration);
            })(),
            // Pour garder le token dans le cache pendant la durée de la session
            cacheTime: Infinity,
            staleTime: Infinity,
        });
    },

    useStructuresByLeads() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: structuresByLeads,
            onSuccess: async (data) => {
                dispatch(setStructuresByLeads(data))
            }
        });
    }
}

export default adminService;
