import "./info.css"

export const Info = ({info}) => {

    return (
        <span className={"info hover"}>
           i
            <div className={"info-bubble hover"}>
                {info}
            </div>
        </span>
    );

};