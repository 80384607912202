import {useMutation} from "@tanstack/react-query";
import {dynamicLeadsRoute, leadsRoute} from "../utils/APIRoutes";
import {useDispatch} from "react-redux";
import {setLeads, setMore} from "../Models/leads";
import {apiClient} from "./clients";


const get = async ({max}) => {
    try {
        const params = new URLSearchParams({max}).toString();

        const response = await apiClient.get(`${leadsRoute}?${params}`);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};

const more = async ({skip, max, type}) => {
    try {
        const params = new URLSearchParams({skip, max, type}).toString();
        const response = await apiClient.get(`${dynamicLeadsRoute}?${params}`);
        return response.data;
    } catch (e) {
        console.log(e)
    }

};


const exportRequest = async (data) => {
    try {
        const response = await apiClient.post(`${leadsRoute}export`, data, {
            responseType: 'blob'
        });
        return {data: response.data, format: data.format};
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
}


const unlockRequest = async () => {
    try {
        const response = await apiClient.get(`${leadsRoute}unlock`);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
}
const leadsService = {
    useFetch() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: get,
            onSuccess: async (data) => {
                dispatch(setLeads(data));
            },

        });
    },

    useFetchMore() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: more,
            onSuccess: async (data) => {
                dispatch(setMore(data));
            },

        });
    },

    useExport() {
        return useMutation({
            mutationFn: exportRequest,
            onSuccess: async ({data, format}) => {
                const blob = new Blob([data], {type: format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `leads.${format}`);
                document.body.appendChild(link);
                link.click();
            },
        });
    },

    useUnlock() {
        return useMutation({
            mutationFn: unlockRequest,
            onSuccess: async (data) => {
                window.location.href = data.url;
            }
        });
    }


}

export default leadsService;
