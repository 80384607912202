import {useDispatch, useSelector} from "react-redux";
import {Table} from "../../Table/Table";
import {getColumnKeys, getColumnValues} from "../../Leads/TableDecorator";
import React, {useEffect} from "react";
import {changeForAllLeads, moveToExported, setSelectedSubFilter, setSubFilterLoading} from "../../../Models/admin";
import adminService from "../../../Services/adminService";
import {ExportLeadsBtn} from "../../Leads/ExportBtn/ExportLeadsBtn";
import {StructuresByLeads} from "./StructuresByLeads";


export const TableLeadsDecorator = () => {

    const dispatch = useDispatch();
    const selectedType = useSelector(state => state.admin[state.admin.selectedFilter]);

    const data = selectedType[selectedType.selected];
    const selectedStructure = useSelector(state => state.admin.leads.selectedStructure);

    const onSelect = (filter) => {
        dispatch(setSelectedSubFilter(filter));
    }

    const columnKeys = getColumnKeys(selectedType?.columns);
    const columnValues = getColumnValues(selectedType?.columns);

    const formattedFilters = () => {
        return selectedType?.filters?.map((filter) => {
            return {key: filter.key, value: filter.value + " (" + selectedType[filter.key].total + ")"}
        })
    }

    const service = adminService.useLeads();

    useEffect(() => {

        const fetchData = async () => {
            await service.mutateAsync({
                skip: data?.content.length,
                max: data?.max,
                type: selectedType.selected,
                training: selectedType.training,
                forAll: selectedType.forAll,
                structure: selectedStructure
            });
        }

        if (data.loading && ((!selectedType.forAll) || (selectedType.forAll && selectedStructure))) {
            fetchData()
        }

    }, [selectedType.selected, selectedType.forAll, selectedStructure]);

    const handleChange = async (data) => {
        dispatch(changeForAllLeads(data))
    }
    const fetchMore = async () => {
        try {
            dispatch(setSubFilterLoading({
                selectedFilter: "leads",
                selected: selectedType.selected,
                loading: true
            }))
            await service.mutateAsync({
                skip: data?.content.length,
                max: 15,
                type: selectedType.selected,
                forAll: selectedType.forAll,
                structure: selectedStructure
            })
        } catch (e) {
            console.error(e)
        }
    }

    const moveTo = (skip, max, from) => {
        dispatch(moveToExported({skip, max, from}));
    }


    return (
        <>
            <div style={{marginTop: 20}}>
                <label className={"row2"} style={{gap: 10, marginBottom: 20, width: "fit-content"}}>
                    <input
                        onChange={() => handleChange(!selectedType.forAll)}
                        type="checkbox"
                        checked={selectedType.forAll}
                        disabled={service.isPending}
                    />
                    Afficher pour toutes les écoles
                </label>

                {
                    !selectedType.forAll &&
                    <ExportLeadsBtn max={data.total} styles={{marginBottom: 20}} type={selectedType.selected}
                                    moveTo={moveTo}/>
                }

                <div className={"row2"} style={{alignItems: "baseline", gap: 20, height: "72vh"}}>
                    {selectedType.forAll && <StructuresByLeads/>}

                    {selectedType.forAll && !selectedStructure &&
                        <div style={{flex: 1}}><h2>{"<-"} Sélectionne une structure</h2></div>}
                    {((selectedStructure && selectedType.forAll) || (!selectedType.forAll)) &&
                        <div style={{flex: 1}} className={"forallleads"}>
                            {selectedType.forAll && selectedStructure &&
                                <ExportLeadsBtn max={data.total} styles={{marginBottom: 20}}
                                                type={selectedType.selected}
                                                moveTo={moveTo} structure={selectedStructure}/>}

                            <div className={"table-container"}>
                                <Table columns={columnValues} rows={data?.content} total={data.total}
                                       loading={data?.loading}
                                       disabled={service.isPending}
                                       filters={formattedFilters()}
                                       fetchMore={fetchMore}
                                       onSelect={onSelect} rowsFormat={columnKeys}
                                       selected={selectedType.selected}/>
                            </div>

                        </div>
                    }


                </div>


            </div>
        </>
    )
}