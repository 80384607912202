import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {trackEvent2} from "../../../../../utils/ga";
import {setRanksOptions} from "../../../../../Models/metrics";

const RangeFilter = ({filter, rangeValue, setRangeValue, setIsOpen}) => {
    const dispatch = useDispatch();

    const {min, max} = useSelector(state => state.stats.filters.ranks.options);
    const handleRangeChange = (value) => {
        setRangeValue(value);
    };

    const client = useSelector(state => state.client);

    const handleSave = () => {
        dispatch(setRanksOptions({min: rangeValue[0], max: rangeValue[1]}));
        setIsOpen(false);

        if (!client.admin) {
            trackEvent2("filters_event", "Metrics", "apply", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }

    };

    const handleClear = () => {
        setRangeValue([filter.options.min, filter.options.max]);
        if (!client.admin) {
            trackEvent2("filters_event", "Metrics", "clear", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }

    };


    const handleInputChange = (index, event) => {
        const value = event.target.value;
        if (value === '' || !isNaN(Number(value))) {
            const newRangeValue = [...rangeValue];
            newRangeValue[index] = value === '' ? '' : Number(value);
            setRangeValue(newRangeValue);
        }
    };
    return (
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>

            <div style={{width: "90%", margin: "auto"}}>
                <div className="row" style={{marginBottom: 10, justifyContent: 'space-between'}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 10, alignItems: "center"}}>
                        <input
                            type="number"
                            value={rangeValue[0]}
                            onChange={(event) => handleInputChange(0, event)}
                            style={{width: "50px", paddingRight: 10}}
                        />
                        <label>min</label>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 10, alignItems: "center"}}>
                        <input
                            type="number"
                            value={rangeValue[1]}
                            onChange={(event) => handleInputChange(1, event)}
                            style={{width: "50px", paddingRight: 10}}
                        />
                        <label>max</label>
                    </div>

                </div>
                <Slider
                    range
                    min={min}
                    max={max}
                    defaultValue={[filter.options.min, filter.options.max]}
                    value={rangeValue}
                    onChange={handleRangeChange}
                    trackStyle={{backgroundColor: '#3784EB'}}
                    handleStyle={{
                        borderColor: '#3784EB',
                        boxShadow: '0 0 0 5px rgba(55, 132, 235, 0.2)'
                    }}
                />
            </div>

            <div className={"row"}>
                <button onClick={handleClear}>Effacer</button>
                <button onClick={handleSave}>Enregistrer</button>
            </div>
        </div>
    );
};

export default RangeFilter;
