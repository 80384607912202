export const calculateFilterOptionsVisits = (trainings, selectedTraining) => {
    const labelSet = {
        region: new Set(),
        filiere: new Set(),
        localisation: new Set(),
        ranks: {min: 0, max: 0}
    };

    if (selectedTraining) {
        if (trainings[selectedTraining] && trainings[selectedTraining].visits) {
            const training = trainings[selectedTraining];
            aggregateLabels(training.visits.metadata.region, labelSet.region);
            aggregateLabels(training.visits.metadata.filiere, labelSet.filiere);
            aggregateLabels(training.visits.metadata.localisation, labelSet.localisation);
            labelSet.ranks.min = training.visits.metadata.ranks.min;
            labelSet.ranks.max = training.visits.metadata.ranks.max;
        }
    } else {
        Object.values(trainings).forEach(training => {
            if (training.visits) {
                aggregateLabels(training.visits.metadata.region, labelSet.region);
                aggregateLabels(training.visits.metadata.filiere, labelSet.filiere);
                aggregateLabels(training.visits.metadata.localisation, labelSet.localisation);
                labelSet.ranks.min = Math.min(labelSet.ranks.min, training.visits.metadata.ranks.min);
                labelSet.ranks.max = Math.max(labelSet.ranks.max, training.visits.metadata.ranks.max)
            }
        });
    }

    return {
        region: Array.from(labelSet.region).map(label => ({label, selected: false})),
        filiere: Array.from(labelSet.filiere).map(label => ({label, selected: false})),
        localisation: Array.from(labelSet.localisation).map(label => ({label, selected: false})),
        ranks: {min: labelSet.ranks.min, max: labelSet.ranks.max}
    };
};


export const calculateFilterOptionsPublications = (selectedPublication) => {
    const labelSet = {
        region: new Set(),
        filiere: new Set(),
        localisation: new Set()
    };


    if (selectedPublication) {
        aggregateLabels(selectedPublication.metadata?.regions, labelSet.region);
        aggregateLabels(selectedPublication.metadata?.filieres, labelSet.filiere);
        aggregateLabels(selectedPublication.metadata?.localisations, labelSet.localisation);
    }


    return {
        region: Array.from(labelSet.region).map(label => ({label, selected: false})),
        filiere: Array.from(labelSet.filiere).map(label => ({label, selected: false})),
        localisation: Array.from(labelSet.localisation).map(label => ({label, selected: false}))
    };
}
export const aggregateLabels = (labels, labelSet) => {
    if (!labels) return;
    labels.forEach(label => {
        labelSet.add(label);
    });
};


export const calculateFilterOptionsStrengths = (filtersToFormat, selectedTraining) => {
    const labelSet = {
        region: new Set(),
        filiere: new Set(),
        localisation: new Set(),
        categories: new Set()
    };

    if (selectedTraining) {
        const training = filtersToFormat[selectedTraining];
        if (training) {
            aggregateLabels(training.regions, labelSet.region);
            aggregateLabels(training.filieres, labelSet.filiere);
            aggregateLabels(training.localisations, labelSet.localisation);
            aggregateLabels(training.categories, labelSet.categories);

        }
    } else if (filtersToFormat) {
        Object.values(filtersToFormat).forEach(training => {
            if (training) {
                aggregateLabels(training.regions, labelSet.region);
                aggregateLabels(training.filieres, labelSet.filiere);
                aggregateLabels(training.localisations, labelSet.localisation);
                aggregateLabels(training.categories, labelSet.categories);

            }
        });
    }

    return {
        region: Array.from(labelSet.region).map(label => ({label, selected: false})),
        filiere: Array.from(labelSet.filiere).map(label => ({label, selected: false})),
        localisation: Array.from(labelSet.localisation).map(label => ({label, selected: false})),
        categories: Array.from(labelSet.categories).map(label => ({label, selected: false}))
    };
};
