import React, {useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip} from 'chart.js';
import {useSelector} from "react-redux";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = ({labels, data, complements}) => {

    const [refreshKey, setRefreshKey] = useState(0);


    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "Étudiants",
                data: data,
                backgroundColor: '#3784EB',
                borderColor: '#3784EB',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        animation: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                padding: 15,
                text: complements?.title,
                font: {
                    size: 17,
                    color: "#7A8596"
                },
            },
            tooltip: {
                mode: 'index',  // Affiche les données de la colonne entière
                intersect: false,  // Affiche même si le curseur n'intersecte pas un point
                displayColors: false, // This line disables the color box in the tooltip
                titleFont: {
                    size: 14, // Taille du texte du titre
                    weight: 'bold',
                },
                bodyFont: {
                    size: 16, // Taille du texte du corps
                    weight: 'bold',
                },

                footerFont: {
                    size: 14, // Taille du texte du pied de page
                    weight: 'normal',
                },
                callbacks: complements?.options ?? {}
            },
            crosshair: {
                line: {
                    color: '#d3d3d3',  // Couleur du trait pointillé
                    width: 1,  // Largeur du trait pointillé
                    dashPattern: [5, 5]
                },
                sync: {
                    enabled: false,  // Synchronisation des curseurs entre plusieurs graphiques (désactivé ici)
                },
                zoom: {
                    enabled: false,  // Zoom activé par le crosshair (désactivé ici)
                },
            }
        },
        scales: {
            x: {

                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    callback: function (value, index) {
                        // Display every 10th label
                        return complements?.hiddeX ? "" : index % 10 === 0 ? this.getLabelForValue(value) : '';
                    }
                },
                title: {
                    display: true,
                    padding: 15,
                    text: complements?.titlex,
                    font: {
                        weight: 'bold',
                        size: 17 // Increase the size of the y-axis title
                    }
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: complements?.titley,
                    padding: 15,
                    font: {
                        weight: 'bold',
                        size: 17 // Increase the size of the y-axis title
                    }
                },
            },
        },
    };

    const refreshChart = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        const handleResize = () => {
            refreshChart();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const activeNavBar = useSelector((state) => state.app.activeNavBar);

    useEffect(() => {
        setTimeout(() => {
            refreshChart()
        }, 200);

    }, [activeNavBar]);

    return (
        <div className={"graph"}>
            <Bar data={chartData} options={options} key={refreshKey} plugins={[{
                beforeDraw: (chart) => {
                    if (chart.tooltip._active && chart.tooltip._active.length) {
                        const ctx = chart.ctx;
                        const x = chart.tooltip._active[0].element.x;
                        const topY = chart.scales.y.top;
                        const bottomY = chart.scales.y.bottom;

                        ctx.save();
                        ctx.beginPath();
                        ctx.setLineDash([5, 5]);
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#d3d3d3';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }]}/>
        </div>);

};

export default BarChart;
