import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {setFromDate, setToDate} from "../../../Models/metrics";
import {trackEvent2} from "../../../utils/ga";

export const DateSelector = () => {

    const dispatch = useDispatch();
    const from = useSelector(state => state.stats.from);
    const to = useSelector(state => state.stats.to);
    const min = useSelector(state => state.stats.minDate);
    const max = useSelector(state => state.stats.maxDate);

    const fromDate = from ? new Date(from) : null;
    const toDate = to ? new Date(to) : null;
    const minDate = min ? new Date(min) : null;
    const maxDate = max ? new Date(max) : null;

    const client = useSelector(state => state.client);

    const handleChangeFrom = (date) => {
        dispatch(setFromDate(date.toISOString()));

        if (!client.admin) {
            trackEvent2("select_event", "Metrics", "change", "date", {
                structureId: client.structureId,
                acronym: client.acronym,
            });
        }
    };

    const handleChangeTo = (date) => {
        dispatch(setToDate(date.toISOString()));
        if (!client.admin) {
            trackEvent2("select_event", "Metrics", "change", "date", {
                structureId: client.structureId,
                acronym: client.acronym,
            });
        }
    };


    return (
        <div className="row2 datepicker">
            Du :
            <DatePicker
                selected={fromDate}
                dateFormat="dd/MM/yyyy"
                minDate={minDate}
                maxDate={toDate}
                onChange={handleChangeFrom}
            />
            au :{" "}
            <DatePicker
                selected={toDate}
                dateFormat="dd/MM/yyyy"
                minDate={fromDate}
                maxDate={maxDate}
                onChange={handleChangeTo}
            />
        </div>
    );
}