import {useEffect} from "react";
import leadsService from "../Services/leadsService";
import {useSelector} from "react-redux";
import {CircularLoader} from "../components/LoadingComponents/CircularLoader";
import {TableLeadsDecorator} from "../components/Leads/TableDecorator";

export const Leads = () => {

    const service = leadsService.useFetch();
    const leads = useSelector(state => state.leads)

    useEffect(() => {

        const fetch = async () => {
            await service.mutateAsync({skip: 0, max: leads.leads.max, type: "all"});
        }
        if (!leads.loaded) {
            fetch()
        }

    }, [leads.loaded]);
    return (
        <div className="fragment">
            <div className="fragment_left stats">
                {!leads.loaded ? <div style={{
                        width: "100%",
                        height: 400,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}><CircularLoader width={70} height={70}/>
                    </div> :
                    <>
                        <TableLeadsDecorator/>
                    </>
                }
            </div>
        </div>
    );
}

