import appico from "../../../assets/hopteo_manager.webp";
import {Link} from "react-router-dom";
import landingImage from "../../../assets/Landing Image.svg";
import "./style.css";
import {motion, useInView} from "framer-motion";
import {useRef} from "react";

export const Home = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    return (
        <section id="section1" ref={ref}>

            <motion.div
                initial={{opacity: 0, y: 100}}
                transition={{ease: "easeOut", duration: 0.4}}
                animate={isInView ? {opacity: 1, y: 0} : {opacity: 0, y: 100}}
            >
                <img
                    src={appico}
                    alt="Logo hopteo manager"
                    id="headerico"
                    style={{
                        width: 350, lineHeight: 50, filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))"
                    }}
                />
                <h1 style={{marginTop: 20}}>
                    Valorisez vos formations
                    <br/> auprès des étudiants qui vous
                    <br/> intéressent vraiment
                </h1>
                <div
                    className={"row2"}
                    style={{gap: "10px 30px", marginTop: 20, flexWrap: "wrap"}}
                >
                    <Link className={"landing-btn register"} to={"/register"}>
                        Demander un rendez-vous
                    </Link>
                    <Link className={"landing-btn"} id="login" to={"/login"}>
                        Se connecter
                    </Link>
                </div>
            </motion.div>
            <motion.img
                initial={{opacity: 0, x: 100}}
                transition={{ease: "easeOut", duration: 0.4}}
                animate={isInView ? {opacity: 1, x: 0} : {}}
                src={landingImage}
                alt="Hopteo manager landing"
                style={{width: "clamp(300px, 30vw + .1rem, 600px)"}}
            />
        </section>
    );
};
