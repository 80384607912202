import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    content: [],
    pending: [],
    loaded: false
};

export const trainingsSlice = createSlice({
    name: "trainings",
    initialState,
    reducers: {
        setTrainings: (state, action) => {
            state.content = action.payload || [];
            state.loaded = true;
        },
        updateTrainingField: (state, action) => {
            const {id, field, value} = action.payload;
            const trainingIndex = state.content.findIndex(training => training.trainingId === id);
            if (trainingIndex !== -1) {
                if (field === "publications") {
                    state.content[trainingIndex].publications = state.content[trainingIndex].publications.filter((publication) => publication.filename !== value)
                } else {
                    state.content[trainingIndex][field] = value;

                }
            }
        },
        setPublications: (state, action) => {
            const {id, value} = action.payload;
            const trainingIndex = state.content.findIndex(training => training.trainingId === id);

            if (trainingIndex !== -1) {
                // Utilisation de immer pour manipuler l'état de manière immuable
                const training = state.content[trainingIndex];

                state.content[trainingIndex] = {
                    ...training,
                    publications: value,
                    publicationLoaded: true
                };

            } else {
                console.error(`Training avec l'id ${id} non trouvé.`);
            }
        },
        addPublicationMethod: (state, action) => {
            const {id, publicationUrl} = action.payload;
            const trainingIndex = state.content.findIndex(training => training.trainingId === id);
            if (trainingIndex !== -1) {
                const publications = state.content[trainingIndex].publications || [];
                state.content[trainingIndex].publications = [{
                    filename: publicationUrl,
                    data: null,
                    views: 0,
                    likes: 0,
                    createdAt: new Date()
                }, ...publications];
            }
        },
    }
});

export const {setPublications, setTrainings, updateTrainingField, addPublicationMethod} = trainingsSlice.actions;
