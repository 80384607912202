export const StatComponent = ({data, label}) => {
    return (<div style={{
        width: "170px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        gap: 20

    }}

                 className={"statComponent"}
    >
        <div className={"databubble"}>
            <h3 className={""}>{data}</h3>
        </div>
        <h3>{label}</h3>
    </div>)

}