import {useSelector} from "react-redux";
import "../style.css";
import {Question} from "./Question";
import {useEffect, useState} from "react";
import {FAQForm} from "../Create/FAQForm";
import {CircularLoader} from "../../LoadingComponents/CircularLoader";
import qnaService from "../../../Services/qnaService";
import {trackEvent2} from "../../../utils/ga";

export const Displayed = ({selectedTraining}) => {
    const loading = useSelector(state => state.qna.displayed.loading);
    const displayed = useSelector(state => state.qna.displayed);

    const trainings = useSelector(state => state.trainings.content); // Assuming trainings are stored in state.trainings.content
    const [newQuestion, setNewQuestion] = useState(false);

    // Filtrer les questions si selectedTraining est défini
    const filteredDisplayed = selectedTraining
        ? displayed.content.filter(question => question.trainingIds.includes(selectedTraining))
        : displayed.content;

    const client = useSelector(state => state.client);

    const service = qnaService.useDisplayed();
    useEffect(() => {

        if (loading) {
            fetch()
        }

    }, []);


    const fetch = async () => {
        await service.mutateAsync({skip: displayed.content.length, max: displayed.max});
    }

    const handleClickNewQuestion = () => {
        setNewQuestion(!newQuestion);

        if (!client.admin) {
            trackEvent2("toggle_event", "FAQ", newQuestion ? "close" : "open", "", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }

    return (
        <div>
            <div className={"row"}
                 style={{alignItems: "center", borderBottom: "1px solid var(--orange)", paddingBottom: 10}}>
                <h3>Questions affichées sur vos pages formation ({displayed.total})</h3>
                <button onClick={() => handleClickNewQuestion()}>{!newQuestion ? "+" : "Annuler"}</button>
            </div>

            {newQuestion && <FAQForm trainings={trainings} setNewQuestion={setNewQuestion}/>}

            <div className={"colum-container"}>
                {loading && (
                    <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <CircularLoader/>
                    </div>
                )}
                {filteredDisplayed?.map((question, index) => {
                    const trainingIndexes = question.trainingIds.map(id => {
                        const trainingIndex = trainings.findIndex(t => t.trainingId === id);
                        return trainingIndex > -1 ? trainingIndex + 1 : '';
                    });
                    return (
                        <Question
                            key={question._id}
                            question={question}
                            trainingIndexes={trainingIndexes}
                            selectedTraining={selectedTraining}
                            index={index}
                        />
                    );
                })}
                {filteredDisplayed.length === 0 && !loading && <p>Aucune question pour le moment...</p>}
            </div>

            {displayed.content.length < displayed.total &&
                <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}>
                    {service.isPending ? <CircularLoader/> : <button onClick={() => fetch()}>Voir plus</button>}
                </div>
            }

        </div>
    );
};
