import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clientService from "../../Services/clientService";
import {toast} from "react-toastify";
import {toastOptions} from "../../Functions";

// Définition du schéma de validation
const schema = yup.object({
    subject: yup.string().required("Le sujet est requis.").min(4, "Le sujet doit contenir au moins 4 caractères"),
    content: yup.string().required("Un message est requis.").min(10, "Le message doit contenir au moins 10 caractères")
}).required();

const Contact = () => {
    const {reset, register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });

    const service = clientService.useContact();

    const onSubmit = async (data) => {
        try {
            await service.mutateAsync(data);
            reset();
            toast.success("Votre message a bien été envoyé !", toastOptions)
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <div className="prof">
                <h2>Pour nous contacter</h2>
            </div>
            <div className="frag-field" style={{marginTop: 10}}>
                <h3>Numéro de téléphone : </h3>
                <span>+33 6 99 68 91 11</span>
            </div>
            <div className="frag-field" style={{marginTop: 10}}>
                <h3>Notre adresse mail : </h3>
                <span>hopteomanager@hopteo.com</span>
            </div>

            <div className="contact-form">
                <h2>Formulaire de contact</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="labs">
                        <label htmlFor="subject">Sujet du message</label>
                        <input
                            id="subject"
                            {...register("subject")}
                            placeholder="Ex : Demande de renseignement..."
                        />
                        {errors.subject && <p className="error-static">{errors.subject.message}</p>}
                    </div>

                    <div className="labs">
                        <label htmlFor="content">Votre message</label>
                        <textarea
                            id="content"
                            {...register("content")}
                            placeholder="Rentrez votre message ici..."
                        />
                        {errors.content && <p className="error-static">{errors.content.message}</p>}

                    </div>
                    {service.error && <p className="error-static">{service.error?.message}</p>}

                    <button className="l-but" type="submit" disabled={service.isPending}>
                        {service.isPending ? "Envoie..." : "Envoyer →"}
                    </button>
                </form>
            </div>
        </>
    );
};

export default Contact;
