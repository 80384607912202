import "./selector.css";
import adminService from "../../../Services/adminService";
import {useState} from "react";
import {useSelector} from "react-redux";
import {Icon} from "../../Icons/Icon";

export const StructureSelector = ({onSelect}) => {

    const clientName = useSelector(state => state.client.acronym);
    const service = adminService.useStructures();

    const [search, setSearch] = useState(clientName);
    const [focus, setFocus] = useState(false);

    const [structures, setStructures] = useState([]);

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const searchMethod = async (data) => {
        if (data?.length > 2) {
            const result = await service.mutateAsync({search: data});
            setStructures(result)
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchMethod(e.target.value);
        }
    };

    return (
        <div id="adminsearch">
            <input
                type="search"
                value={search}
                onChange={(e) => handleSearch(e)}
                onKeyDown={handleKeyDown}
                onFocus={() => setFocus(true)}
                onBlur={() =>
                    setTimeout(() => {
                        setFocus(false)
                    }, 200)
                }
            />

            <Icon
                name="search"
                color={"#F19829"}
                size={12}
                type={"filled"}
            />

            {focus && (
                <>
                    <div className="schoolscontainer hover">
                        {structures.length > 0 ? (
                            structures.map((school) => {
                                return (
                                    <div
                                        key={school._id}
                                        onClick={() => {
                                            setSearch(school.acronym)
                                            onSelect(school)
                                            setFocus(false)
                                        }}
                                    >
                                        {school.acronym}
                                    </div>
                                );
                            })
                        ) : (
                            <div>Aucun résultat</div>
                        )}
                    </div>
                </>
            )}

        </div>
    );
}