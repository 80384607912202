import {useForm} from 'react-hook-form';
import trainingsService from "../../../Services/trainingsService";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup.object().shape({
    content: yup.string(),
    paymentMode: yup.string().oneOf(['carte', 'chèque', 'virement'], 'Mode de paiement invalide').default('carte').required(),
    studentsCount: yup.number().positive('La valeur doit être un nombre supérieur à 0').integer('La valeur doit être un nombre entier').default(0).required(),
});

export const UpgradeRequest = ({training, handleSuccess}) => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            paymentMode: 'carte',
            studentsCount: 0
        }
    });

    const service = trainingsService.useUpgradeTraining();

    const onSubmit = async (data) => {
        try {
            await service.mutateAsync({
                trainingId: training.trainingId,
                content: data.content,
                paymentMode: data.paymentMode,
                studentsCount: data.studentsCount,
            });
            handleSuccess({
                trainingId: training.trainingId,
                content: data.content,
                paymentMode: data.paymentMode,
                studentsCount: data.studentsCount,
                status: "pending"
            });
        } catch (e) {
            // Handle error
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div style={{display: "flex", gap: 10, flexDirection: "column"}}>
                <span>Une fois votre demande soumise, nos modérateur vérifierons l'authenticité des informations fournies et vous recontacterons.</span>
                <span>À noter que si le paiement est par carte, une fois les informations validées, un lien de paiement vous sera envoyé par mail.</span>
            </div>

            <div style={{display: "flex", flexDirection: "column", gap: 10, marginTop: 20}}>
                <div className={"row2"}>
                    <label htmlFor={"paymentMode"}>Mode de paiement *</label>
                    <select id="paymentMode" {...register("paymentMode")} className={"hover"} style={{width: 150}}>
                        <option value="carte">Carte</option>
                        <option value="chèque">Chèque</option>
                        <option value="virement">Virement</option>
                    </select>
                    {errors.paymentMode && <p className={"error-static"}>{errors.paymentMode.message}</p>}
                </div>

                <div className={"row2"}>
                    <label htmlFor={"studentsCount"}>Nombre d'étudiants *</label>
                    <input
                        type="number"
                        id="studentsCount"
                        {...register("studentsCount")}
                    />
                    {errors.studentsCount && <p className={"error-static"}>{errors.studentsCount.message}</p>}
                </div>

                <div style={{marginTop: 20}}>
                    <label htmlFor={"content"}>Des précisions à ajouter ?</label>
                    <textarea
                        id={"content"}
                        placeholder={"Potentielles instructions, demandes..."}
                        {...register("content")}
                    />
                    {errors.content && <p className={"error-static"}>{errors.content.message}</p>}
                </div>

                <button
                    type="submit"
                    style={{
                        width: "fit-content",
                        backgroundColor: "var(--orange)",
                        color: "white",
                        marginLeft: "auto"
                    }}
                    disabled={service.isPending}
                >
                    {service.isPending ? "Chargement..." : "Envoyer"}
                </button>
            </div>

            {service.error && <span className={"error"} style={{position: "static"}}>{service.error.message}</span>}
        </form>
    );
};
