import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    displayed: {content: [], total: 0, loading: true, max: 15},
    pending: {
        content: {
            "Tout": {content: [], total: 0, loading: true},
            "Lu": {content: [], total: 0, loading: true},
            "Non lu": {content: [], total: 0, loading: true},
            "Archive": {content: [], total: 0, loading: true}
        },
        totals: {
            "Tout": 0,
            "Lu": 0,
            "Non lu": 0,
            "Archivé": 0
        },
        loading: true
    },
    filters: ["Tout", "Lu", "Non lu", "Archive"],
    activeFilter: "Tout",
    loaded: false
};

export const qnaSlice = createSlice({
    name: "qna",
    initialState,
    reducers: {
        setDisplayed: (state, action) => {
            const {content, total} = action.payload;
            state.displayed = {
                content: [...state.displayed.content, ...content] ?? state.displayed.content,
                total: total ?? state.displayed.total,
                loading: false
            }

        },
        setPendingByFilter: (state, action) => {
            const {filter, content, totals, filters} = action.payload;

            if (!state.pending.content[filter]) {
                state.pending.content[filter] = {
                    content: [],
                    loading: true
                };
            }

            state.pending.content[filter] = {
                content: [...state.pending.content[filter].content, ...content],
                loading: false
            };
            state.pending.totals = totals ?? state.pending.totals;
            state.filters = filters ?? state.filters;
        },
        setLoadingForFilter: (state, action) => {
            const {filter} = action.payload;
            state.pending.content[filter].loading = true;
        },
        setActiveFilter: (state, action) => {
            if (!state.pending.content[action.payload]) {
                state.pending.content[action.payload] = {
                    content: [],
                    loading: true
                };
            }
            state.activeFilter = action.payload;
        },
        addQuestion: (state, action) => {
            state.displayed.content.unshift(action.payload);
            state.displayed.total += 1;
        },
        deleteQuestion: (state, action) => {
            const {id} = action.payload;
            const index = state.displayed.content.findIndex(question => question._id === id);
            if (index !== -1) {
                state.displayed.content.splice(index, 1);
            }
            state.displayed.total -= 1;
        },

        patchQuestion: (state, action) => {

            // patch question from all
            const {id, updates} = action.payload;

            const index = state.displayed.content.findIndex(question => question._id === id);
            if (index !== -1) {
                state.displayed.content[index] = {...state.displayed.content[index], ...updates};
            }
        },

        selectQuestion: (state, action) => {
            const {id, topic} = action.payload;
            // Iterate over all pending lists and remove the question
            for (let key in state.pending.content) {
                const index = state.pending.content[key].content.findIndex(question => question._id === id);
                if (index !== -1) {
                    const question = state.pending.content[key].content[index];
                    state.pending.content[key].content.splice(index, 1);

                    if (key === topic) {
                        state.pending.totals[key] -= 1;
                        state.displayed.total += 1;
                        question.selected = true;
                        question.firstEdit = true;
                        state.displayed.content.unshift(question);
                    } else {
                        state.pending.totals[key] -= 1;
                    }
                }
            }
        },
        archiveQuestion: (state, action) => {
            const {id, topic} = action.payload;

            let question;
            const index = state.displayed.content.findIndex(question => question._id === id);
            if (index !== -1) {
                question = state.displayed.content[index];
                state.displayed.content.splice(index, 1);
                state.displayed.total -= 1;
            }
            const alreadyInPending = state.pending.content["Archive"]?.content.some(q => q._id === id);
            if (!alreadyInPending && question) {
                question.archived = true;
                state.pending.content["Archive"].content.unshift(question);
            }
        },
        markAsRead: (state, action) => {
            const {id} = action.payload;
            let questionFound = false;

            // Iterate over all pending lists to find and update the question
            for (let key in state.pending.content) {
                const index = state.pending.content[key].content.findIndex(question => question._id === id);
                if (index !== -1) {
                    state.pending.content[key].content[index].read = true;
                    questionFound = true;
                }
            }

            // Move the question from "Non lu" to "Lu" if found in "Non lu"
            if (questionFound) {
                const nonLuIndex = state.pending.content["Non lu"].content.findIndex(question => question._id === id);
                if (nonLuIndex !== -1) {
                    const [question] = state.pending.content["Non lu"].content.splice(nonLuIndex, 1);
                    state.pending.content["Lu"].content.unshift(question);

                    // Update totals
                    state.pending.totals["Non lu"] -= 1;
                    state.pending.totals["Lu"] += 1;
                }
            }
        }
    }
});

export const {
    deleteQuestion,
    addQuestion,
    setDisplayed,
    setPendingByFilter,
    setLoadingForFilter,
    setActiveFilter,
    markAsRead,
    patchQuestion,
    selectQuestion,
    archiveQuestion
} = qnaSlice.actions;

export default qnaSlice.reducer;
