import {NavLink} from "react-router-dom";
import "./style.css"

export const ParamsSelector = () => {


    const logout = () => {
        localStorage.removeItem("token")
        window.location.reload()
    }

    return (

        <div className={"params-selector hover"}>
            <NavLink to={"/params/account"}
            >Votre compte</NavLink>

            <NavLink to={"/params/etablissement"}
            >Votre établissement</NavLink>

            <NavLink to={"/params/suggestions"}
            >Suggérer une amélioration</NavLink>

            <NavLink to={"/params/bug"}
            >Signaler un bug</NavLink>

            <NavLink to={"/params/contact"}
            >Nous contacter</NavLink>

            <button onClick={logout}>Se déconnecter</button>

        </div>

    )
}