import "./form.css";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import authService from "../../Services/clientService";
import {InputForm} from "./Input/Input";

const Login_Form = () => {
    const navigate = useNavigate();

    const schema = yup
        .object({
            email: yup.string().email("Invalid email.").required("Email is required."),
            password: yup.string().required("Password is required.")
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(schema)
    });

    const {mutateAsync, error, isPending} = authService.useLogin();

    const onSubmit = async (data) => {
        try {
            await mutateAsync(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRegister = (e) => {
        e.preventDefault();
        navigate("/register");
    }

    return (
        <div className="login">
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className="reponsive-back" onClick={() => navigate("/")}>
                    {"<- "}
                    Retour
                </p>
                <div className="labs">
                    <label>Identifiant</label>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                            <InputForm field={field} error={errors.email} type={"email"}/>
                        )}
                    />
                </div>
                <div className="labs">
                    <label>Mot de passe</label>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                            <InputForm field={field} error={errors.password} type={"password"}/>
                        )}
                    />
                </div>
                {error && <p className="error">{error?.message}</p>}
                <button className="l-but" type="submit" disabled={isPending}>
                    {isPending ? "Chargement..." : "Connexion →"}
                </button>
                <div className="redirect">
                    <div>
                        <p>Vous n'avez pas de compte ?</p>
                        <button type="button" onClick={handleRegister}>
                            Faire une demande d'inscription
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login_Form;
