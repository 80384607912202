import React, {useState} from "react";
import "./locked.css";
import {UpgradeBtn} from "./UpgradeBtn";

export const NotPremium = ({title, inTime = true}) => {
    const [blink, setBlink] = useState(false);

    const handleBlink = () => {
        setBlink(true);
        setTimeout(() => {
            setBlink(false);
        }, 2000); // Reset after 2 seconds, adjust timing as needed for your blink effect
    };

    return (
        <div style={{height: 360, position: "relative", width: "100%"}} className={blink ? "blink" : ""}>
            <h3>{title}</h3>
            <div className={"locked-content"}>
                <UpgradeBtn/>
                {inTime && <p className={"row2"} style={{display: "block", margin: 0}} onClick={handleBlink}>
                    Afficher l’évolution au cours du temps ->
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                </p>
                }
            </div>
        </div>
    );
}
