import {useMutation} from "@tanstack/react-query";
import {contactRoute, fetchClientData, loginRoute, passwordChangeRoute, registerRequestRoute} from "../utils/APIRoutes";
import {useDispatch} from "react-redux";
import {setClient, setField, setIsLoaded} from "../Models/client";
import {useNavigate} from "react-router-dom";
import {apiClient, fileClient} from "./clients";

const login = async (credentials) => {
    try {
        const response = await apiClient.post(loginRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }

};

const fetch = async (credentials) => {
    try {
        const response = await apiClient.get(fetchClientData, credentials);
        return response.data;
    } catch (e) {
        throw e;
    }

};

const patch = async (credentials) => {
    try {
        const response = await apiClient.post(fetchClientData + "/patch", credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
};

const register = async (credentials) => {
    try {
        const response = await fileClient.post(registerRequestRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }

};

const changePassword = async (credentials) => {
    try {
        const response = await apiClient.post(passwordChangeRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }


}


const contact = async (credentials) => {
    try {
        const response = await apiClient.post(contactRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }
};

const clientService = {
    useLogin() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: login,
            onSuccess: async (data) => {
                dispatch(setIsLoaded(true));
                localStorage.setItem("token", data.token);
                window.location.replace("/");
            },
            onError: async (error) => {
                dispatch(setIsLoaded(true));
            }
        });
    },
    useFetch() {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        return useMutation({
            mutationFn: fetch,
            onSuccess: async (data) => {
                dispatch(setClient(data));
                navigate("/")
            },
            onError: async (error) => {
                const token = localStorage.getItem("token");
                localStorage.removeItem("token");
                dispatch(setIsLoaded(true));
                if (token) {
                    navigate("/login")
                } else {
                    navigate("/")
                }

            }
        });
    },
    usePatch() {
        const dispatch = useDispatch();

        return useMutation({
            mutationFn: patch,
            onSuccess: async (data) => {
                dispatch(setField(data))
            }
        });
    },

    useRegister() {
        return useMutation({
            mutationFn: register,
        });
    },
    useChangePassword() {
        return useMutation({
            mutationFn: changePassword,
        });
    },
    useContact() {
        return useMutation({
            mutationFn: contact,
        });
    },
}

export default clientService;
