import {useDispatch, useSelector} from "react-redux";
import qnaService from "../../../Services/qnaService";
import {markAsRead, selectQuestion} from "../../../Models/Q&A";
import moment from "moment/moment";
import {CircularLoader} from "../../LoadingComponents/CircularLoader";
import {Icon} from "../../Icons/Icon";
import {trackEvent2} from "../../../utils/ga";

export const Question = ({question, trainingIndexes, activeFilter}) => {

    const dispatch = useDispatch();

    const questionService = qnaService.usePatch();

    const client = useSelector(state => state.client);

    const read = async () => {
        if (questionService.isPending) {
            return;
        }
        await questionService.mutateAsync({question: {...question, read: true}})
        dispatch(markAsRead({id: question._id}));

        if (!client.admin) {
            trackEvent2("update_event", "FAQ", "update", "read", {
                structureId: client.structureId,
                acronym: client.acronym,
            });
        }
    }

    const select = async () => {
        if (questionService.isPending) {
            return;
        }
        await questionService.mutateAsync({
            question: {
                ...question,
                ...{
                    selected: true,
                    archived: false
                }
            }
        });

        dispatch(selectQuestion({
            id: question._id,
            topic: activeFilter
        }));

        if (!client.admin) {
            trackEvent2("select_event", "FAQ", "select", "", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }

    return <div className={"question pending"}>
        <div className={"row"}>
            <div className={"row2"} style={{marginBottom: 10, maxWidth: "80%", gap: 20}}>
                {trainingIndexes.map((index, i) => <p key={i} className={"hover"}>Page {index}</p>)}
                <button disabled={true}
                        className={"disabled"}>Elève {question.student} - {moment(question.createdAt).fromNow(true)}</button>
            </div>
            {question.read ? (
                    <div className={"row2"}>
                        <Icon name="check" size={"20"} color={"#2ECC71"}/>
                        Lu
                    </div>) :
                <div className={"row2"}>
                    Non lu
                </div>
            }

        </div>
        <div className={"colum"}>
            <p style={{
                fontFamily: "Inter-SemiBold",
                marginBottom: 10,
                paddingBottom: 10,
                paddingTop: 10
            }}>{question.title}</p>
            <p>{question.answer}</p>
        </div>

        <div className={"row"}>
            <div className={"row2 btn-row"} style={{marginTop: 10}}>
                {!question.archived ? (<>
                    <button onClick={() => select()}>Répondre</button>
                    {!question.read && <button onClick={() => read()}>Marquer comme lu</button>}
                </>) : (
                    <button onClick={() => select()}>Remettre</button>
                )}

            </div>

            {questionService.isPending && <CircularLoader/>}
        </div>


    </div>
}