import {useMutation} from "@tanstack/react-query";
import {qnaRoute} from "../utils/APIRoutes";
import {useDispatch} from "react-redux";
import {addQuestion, deleteQuestion, setDisplayed, setLoadingForFilter, setPendingByFilter} from "../Models/Q&A";
import {apiClient} from "./clients";


const post = async (credentials) => {
    try {
        const response = await apiClient.post(qnaRoute, credentials);
        return response.data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            throw new Error(e.response.data.message);
        }
        throw new Error("Une erreur c'est produite.");
    }

};

const patch = async (credentials) => {
    try {
        const response = await apiClient.post(qnaRoute + "/patch", credentials);
        return response.data;
    } catch (e) {
        throw e;
    }
};


const getPending = async ({filter, skip, max}) => {

    const response = await apiClient.get(`${qnaRoute}pending`, {
        params: {filter, skip, max}
    });
    return response.data;
};

const getDisplayed = async ({skip, max}) => {
    const response = await apiClient.get(`${qnaRoute}displayed`, {
        params: {skip, max}
    });
    return response.data;
};

const remove = async (credentials) => {
    try {
        const response = await apiClient.post(`${qnaRoute}delete/${credentials.questionId}`, credentials);
        return response.data;
    } catch (e) {
        throw e;
    }
}

const qnaService = {
    usePending() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: getPending,
            onMutate: ({filter}) => {
                dispatch(setLoadingForFilter({filter}));
            },
            onSuccess: (data, {filter}) => {

                dispatch(setPendingByFilter({...data, filter: filter}));
            },

        });
    },
    useDisplayed() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: getDisplayed,
            onSuccess: (data) => {
                dispatch(setDisplayed(data));
            },
            onError: (error) => {
                console.error("Failed to fetch displayed questions", error);
            }
        });
    },
    useCreate() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: post,
            onSuccess: async (data) => {
                dispatch(addQuestion(data));
            }
        });
    },
    usePatch() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: patch,
        });
    },
    useDelete() {
        const dispatch = useDispatch();
        return useMutation({
            mutationFn: remove,
            onSuccess: async (data) => {
                dispatch(deleteQuestion({id: data._id}));
            }
        });
    },

}

export default qnaService;
