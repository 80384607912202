import {useSelector} from "react-redux";
import "./selector.css";
import {useEffect, useRef, useState} from "react";
import {Icon} from "../../../Icons/Icon";
import {trackEvent2} from "../../../../utils/ga";

export const Selector = ({selectedTraining, setSelectedTraining, all = false}) => {
    const trainings = useSelector(state => state.trainings);

    const [focus, setFocus] = useState(false);
    const selectorRef = useRef(null);
    const client = useSelector(state => state.client);

    const handleChange = (value) => {
        setSelectedTraining(value);
        setFocus(false);

        if (!client.admin) {
            trackEvent2("select_event", "Trainings", "select", selectedTraining, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }

    };

    const handleClickOutside = (event) => {
        if (selectorRef.current && !selectorRef.current.contains(event.target)) {
            setFocus(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const selectedTrainingData = selectedTraining ? trainings.content.find(t => t.trainingId === selectedTraining) : null;

    return (
        <div className={`selector ${focus ? "active" : ""}`} ref={selectorRef} onClick={() => setFocus(!focus)}>
            <div className="selector-label">
                {selectedTraining ? (
                    <>
                        {selectedTrainingData?.subscription?.status === "active" &&
                            <Icon name={"diamon"} size={16} type={"filled"}/>}
                        <span
                            className="text">{`Page ${trainings.content.findIndex(t => t.trainingId === selectedTraining) + 1} - ${selectedTrainingData?.trainingLabel}`}</span>
                    </>
                ) : <span className="text">Toutes les pages</span>}
            </div>
            <Icon name={"chevron"} size={16} className={"chevron"}/>

            {focus && (
                <div className={`selector-list hover ${focus ? "active" : ""}`}>
                    {all && (
                        <div className="selector-item" onClick={() => handleChange(null)}>
                            <span className="text">Toutes les pages</span>
                        </div>
                    )}
                    {trainings.content.map((training, index) => {
                        return (
                            <div key={training.trainingId} className="selector-item"
                                 onClick={() => handleChange(training.trainingId)}>
                                {training.subscription?.status === "active" &&
                                    <Icon name={"diamon"} size={16} type={"filled"}/>}
                                <span className="text">Page {index + 1} : {training.trainingLabel}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
