import axios from "axios";

const URL = "";

const fileClient = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

fileClient.interceptors.request.use(
    async config => {
        const token = localStorage.getItem("token"); // Assurez-vous que getToken retourne le token attendu.
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`; // Ajouter le token aux headers
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const apiClient = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json"
    }
});

apiClient.interceptors.request.use(
    async config => {
        const token = localStorage.getItem("token"); // Assurez-vous que getToken retourne le token attendu.
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`; // Ajouter le token aux headers
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export {
    fileClient,
    apiClient,
};

