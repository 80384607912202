import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    isDark: false,
    notifications: [],
    activeNavBar: true
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsDark: (state, action) => {
            state.isDark = action.payload;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setMenu: (state, action) => {
            state.menuOn = action.payload;
        },
        setNavBar: (state, action) => {
            state.activeNavBar = action.payload;
        },

    }
});

export const {setNavBar, setLoading, setIsDark, setNotifications, setMenu} = appSlice.actions;
