import "./style.css"
import {GraphLoader} from "../../../LoadingComponents/GraphLoader";

export const Column = ({metric, loading, handleTempo, label = "Visites", title = "visiteurs"}) => {

    return (<div className={"column graph"}>
        {loading && (
            <GraphLoader/>
        )}
        <h4>Localisation des {title.toLowerCase()}</h4>

        <div style={{marginTop: 10, maxHeight: "82%", overflow: "scroll", paddingRight: 10}}>
            <div className={"row"}>
                <p>Ville</p>
                <p>{label}</p>
            </div>

            {metric?.datasets[0]?.data.map((data, index) => (
                <div key={index} className={"row"}>
                    <p>{metric?.labels[index]}</p>
                    <p>{data}</p>
                </div>
            ))}
        </div>

        <p className={"row2 btn"} onClick={handleTempo}>Afficher les {label.toLowerCase() + " "}
            ->
        </p>
    </div>)
}