import "./admission.css"
import {Row} from "../../Row";

export const Admission = ({admission}) => {

    return (
        <div className={"admission"}>
            <Row value={admission.field} label={"Filière :"}/>
            <Row value={admission.entrancePath} label={"Type de formation :"}/>
            <Row value={admission.testBank} label={"Banque ou école chargé du concours :"}/>
            <Row value={admission.status} label={"Nom du concours :"}/>
            <Row value={admission.nbPlace} label={"Nombre de places ouvertes :"}/>
            <Row value={admission.interfiliere === "nbPlace" ? admission.nbPlace : admission.interfiliere}
                 label={"Nombre d’admis :"}/>

            <div className={"admission-stats"}>

            </div>
        </div>)

}