import appico from "../../../assets/hopteo_manager.webp";
import "./header.css";
import Toggle from "../theme-toggle/toggle";
import defaultpdp from "../../../assets/defaultSchool.png";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getImage} from "../../../utils/APIRoutes";
import {StructureSelector} from "../../Admin/StructureSelector/StructureSelector";
import adminService from "../../../Services/adminService";
import {FloatingMenu} from "./Floating/FloatingMenu";
import {setNavBar} from "../../../Models/app";
import {Icon} from "../../Icons/Icon";
import {Images} from "../../Images/Images";
import {MetricsMainSelector} from "../../Stats/Globals/Selector";
import {trackEvent2} from "../../../utils/ga";

const Header = () => {
    const client = useSelector(state => state.client);
    const service = adminService.useStructure();
    const [menuOn, setMenuOn] = useState(false);

    const activeNavBar = useSelector(state => state.app.activeNavBar);
    const dispatch = useDispatch();

    const onSelect = async (structure) => {
        await service.mutateAsync({newStructureId: structure.structureId});
    }

    const toggleNavBar = () => {
        dispatch(setNavBar(!activeNavBar));
        if (!client.admin) {
            trackEvent2("toggle_event", "Navbar", activeNavBar ? "close" : "open", "", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }

    const handleClick = () => {
        setMenuOn(!menuOn);
        if (!client.admin) {
            trackEvent2("toggle_event", "Floating Menu", menuOn ? "close" : "open", "", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }


    const loading = useSelector(state => state.stats.loading);


    return (
        <>
            <div className={"static"}>
                <div className={"row2"} style={{gap: 20, paddingLeft: 14}} id={"toggle-nav"}>
                    <span onClick={() => toggleNavBar()}>
                        <Icon
                            name="menu"
                            size="24"
                            type={"filled"}
                        />
                    </span>
                    <img src={appico} alt="Logo hopteo manager" id="headerico"/>
                    <MetricsMainSelector loading={loading}/>
                </div>


                <div className={client.admin && "admin"}>
                    {client.admin && (
                        <StructureSelector onSelect={onSelect}/>
                    )}
                    <Toggle/>
                    <div

                        style={{
                            position: "relative",
                            cursor: "pointer",
                            userSelect: "none",
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            gap: 20
                        }}
                    >
                        <p style={{width: "max-content"}}>{client.acronym}</p>
                        <div style={{position: "relative"}}
                             onClick={() => handleClick()}
                             id={"modal-btn"}
                        >
                            {client.admin && <span className={"admin-indicator"}>Admin</span>}
                            <Images src={
                                client.profilePicture
                                    ? getImage + client.profilePicture
                                    : defaultpdp
                            }/>
                        </div>
                        {menuOn && <FloatingMenu setMenuOn={setMenuOn}/>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
