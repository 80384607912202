import { toast, Slide } from "react-toastify";

export const toastOptions = {
  position: "bottom-right",
  autoClose: 6000,
  transition: Slide,
  pauseOnHover: true,
  draggable: false,
  theme: "light",
};

export const tokenExpire = ({ user }) => {
  if (!user) {
    toast.info("Votre session à expiré, reconnectez vous.", toastOptions);
    return;
  }

  toast.info(
    "Votre session vient d'expirer, vous allez être déconnecté...",
    toastOptions
  );

  setTimeout(() => {
    localStorage.removeItem(process.env.REACT_APP_LOCALHOST_KEY);
    window.location.reload();
  }, 3500);
};
