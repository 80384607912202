import "./nav.css";
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {Icon} from "../../Icons/Icon";

const NavBar = () => {

    const appState = useSelector(state => state.app);
    const client = useSelector(state => state.client);

    const location = useLocation(); // Utilisez useLocation pour accéder au chemin actuel

    // Fonction pour déterminer si le lien doit être actif
    const checkActive = (location) => {
        // Active si le chemin commence par /params
        return location.pathname.startsWith("/params");
    };

    return (<aside className={appState.activeNavBar ? "sidebar active" : "sidebar"}>
        <nav>
            <NavLink to="/">
          <span>
               <Icon
                   name="pages"
                   size="28"

               />
            <span>Mes pages Hopteo</span>
          </span>
            </NavLink>

            <NavLink to="/faq">
              <span>
                <Icon
                    name="faq"
                    size="28"
                    type="filled"
                />
                  <span>Questions</span>
              </span>
            </NavLink>


            <NavLink to="/avis">
              <span>
                <Icon
                    name="star"
                    size="28"
                    type="filled"
                />
                  <span>Avis d'étudiants</span>
              </span>
            </NavLink>

            <NavLink to="/statistiques">
              <span>
                 <Icon
                     name="stats"
                     size="28"
                 />
                <span>Statistiques</span>
              </span>
            </NavLink>


            <NavLink to="/leads">
              <span>
                <Icon
                    name="mail"
                    size="28"
                />
                  <span>Leads</span>
              </span>
            </NavLink>

            <div>

                <NavLink to="/offres" style={{display: "block", marginBottom: 10}}>
              <span>
                <Icon
                    name="abonnement"
                    size="28"
                />
                  <span>Offres & Abonnements</span>
              </span>
                </NavLink>


                {client.admin && (<NavLink to="/admin" style={{display: "block", marginBottom: 10}}>
                <span>
                    <Icon
                        name="admin"
                        size="28"
                        type={"filled"}
                    />
                    <span>Admin Center</span></span>
                </NavLink>)}

                <NavLink to="/params/account" className={checkActive(location) && "active"}>
          <span>
            <Icon
                name="params"
                size="28"
                type={"filled"}
            />
            <span>Paramètres</span>
          </span>
                </NavLink>
            </div>
        </nav>
    </aside>);
};


export default NavBar;
