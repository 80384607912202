import React from "react";
import "./style.css";
import logo from "../../../assets/hopteo_manager.webp";
import {CircularLoader} from "../CircularLoader";

const LoadingPage = () => {
    return (
        <div className="loadingpage">

            <img src={logo} alt="logo" className="logo"/>
            <h2>Chargement de votre espace...</h2>
            <div style={{marginTop: 30}}>
                <CircularLoader width={60} height={60}/>

            </div>

        </div>
    );
};

export default LoadingPage;
