import {useDispatch, useSelector} from "react-redux";
import {Images} from "../../../Images/Images";
import {useEffect} from "react";
import statsService from "../../../../Services/statsService";
import {setSelectedPublication} from "../../../../Models/metrics";
import {CircularLoader} from "../../../LoadingComponents/CircularLoader";
import {trackEvent2} from "../../../../utils/ga";
import {checkIfPremium, countPages} from "../../../Client/utils";

export const PubliRow = ({selectedTraining}) => {

    const from = useSelector(state => state.stats.from);
    const to = useSelector(state => state.stats.to);

    const stats = useSelector(state => selectedTraining ? state.stats.publications[selectedTraining] : state.stats.allPublications.content);
    const selectedPublication = useSelector(state => state.stats.selectedPublication);

    const allLoading = useSelector(state => state.stats.allPublications.loading);

    const service = statsService.usePublications();

    const dispatch = useDispatch();

    const allSkip = useSelector(state => Object.keys(state.stats.allPublications.content).length)

    const count = useSelector(state => state.stats.allPublications.globalCount);


    const search = useSelector(state => state.trainings.content);
    const {premiums} = countPages(search);
    const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
    const isPremium = selectedTraining && checkIfPremium(selectedTrainingData) || (!selectedTraining && premiums > 0);
    const client = useSelector(state => state.client);


    const fetchData = async () => {
        if (!isPremium) {
            return;
        }
        await service.mutateAsync({trainingId: selectedTraining, from, to, skip: allSkip || 0});
    }

    useEffect(() => {
        if (!selectedTraining && !allLoading) {
            dispatch(setSelectedPublication({trainingId: selectedTraining, item: stats[Object.keys(stats)[0]]}));
        }

        if ((selectedTraining && !stats) || (!selectedTraining && allSkip === 0)) {
            fetchData()
        } else if (stats) {
            dispatch(setSelectedPublication({trainingId: selectedTraining, item: stats[Object.keys(stats)[0]]}));
        }
    }, [selectedTraining]);

    const handleMore = async () => {
        await fetchData()
    }

    if (!isPremium) {
        return <></>;
    }

    if (!stats) {
        return <CircularLoader/>
    }


    if (selectedTraining && Object.keys(stats).length === 0) {
        return <div className={"row2"}>Aucune publication...</div>
    }


    if (!selectedTraining && !allLoading && count === 0) {
        return <div className={"row2"}>Aucune publication...</div>

    }

    const handleSelectPublication = (publi) => {
        dispatch(setSelectedPublication({
            type: selectedTraining,
            item: stats[publi]
        }));

        if (!client.admin) {
            trackEvent2("select_event", "Metrics", "select", "publications", {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }

    }

    return (
        <div className={"row2"}>
            {service.isPending && <CircularLoader/>}
            {Object.keys(stats).map((publi, index) => {
                return <Images key={index} src={stats[publi].data}
                               onClick={() => handleSelectPublication(publi)}
                               styles={`publi-stats hover ${selectedPublication === stats[publi] ? "selected" : ""}`}/>
            })}

            {!service.isPending && !selectedTraining && count > Object.keys(stats).length &&
                <button onClick={() => handleMore()}>Voir plus</button>}

        </div>
    )
}