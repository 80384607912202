import {useSelector} from "react-redux";
import "./structure.css"
import {useEffect, useState} from "react";
import clientService from "../../../Services/clientService";
import {ProfilePicture} from "./ProfilePicture";
import {CircularLoader} from "../../LoadingComponents/CircularLoader";
import {trackEvent2} from "../../../utils/ga";

export const Structure = () => {
    const client = useSelector(state => state.client);

    const [description, setDescription] = useState(client.description);

    const onChange = (e) => {
        setDescription(e.target.value)
    }

    const {mutateAsync, isPending} = clientService.usePatch();

    const save = async () => {
        try {
            await mutateAsync({field: "description", value: description});
            if (!client.admin) {
                trackEvent2("update_event", "Client", "save", description, {
                    structureId: client.structureId,
                    acronym: client.acronym
                });
            }
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        setDescription(client.description)
    }, [client.description]);

    return (
        <div className={"structure row2"}>
            <ProfilePicture/>
            <div>
                <h1>{client?.acronym}</h1>
                <div className={"row2"}>
                    <textarea value={description}
                              onChange={onChange}
                              placeholder={"Décrivez votre école en 3 lignes..."}/>

                    {description !== client.description &&
                        <>
                            {isPending ? <CircularLoader/> :
                                <button disabled={isPending}
                                        onClick={() => save()}>Sauvegarder</button>

                            }
                        </>}

                </div>
            </div>


        </div>
    )
}