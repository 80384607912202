import clientService from "../../Services/clientService";
import {useEffect} from "react";
import {setIsDark} from "../../Models/app";
import {useDispatch} from "react-redux";


export const ClientLoader = () => {
    const {mutateAsync, isPending} = clientService.useFetch();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                await mutateAsync()
            } catch (e) {
                console.log(e)
            }

        })();
    }, []);

    useEffect(() => {
        if (
            localStorage.getItem("dark_mode") !== undefined &&
            localStorage.getItem("dark_mode") !== null
        ) {
            document.body.classList.add("dark_mode");
            dispatch(setIsDark(true));
        }
    }, []);
    return (

        <>
        </>
    )
}