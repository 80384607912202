import s1 from "./imgs/s1.svg";
import s2 from "./imgs/s2.svg";
import s3 from "./imgs/s3.svg";
import s4 from "./imgs/s4.svg";
import s5 from "./imgs/s5.svg";
import s6 from "./imgs/s6.svg";

import "./style.css"

export const Supports = () => {

    return (
        <section id={"section9"}>
            <h1>Ils nous accompagnent</h1>
            <div className={"row2"}>
                <img src={s1} alt={"support"}/>
                <img src={s2} alt={"support"}/>
                <img src={s3} alt={"support"}/>
                <img src={s4} alt={"support"}/>
                <img src={s5} alt={"support"}/>
                <img src={s6} alt={"support"}/>
            </div>
        </section>
    )
}