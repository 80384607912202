import {createSlice} from '@reduxjs/toolkit';

// Initial state
const initialState = {
    verified: {},
    max: 15,
    filters: [
        {key: "verified", value: "Avis vérifiés"},
    ],
    selected: "verified",
};

// Create slice
export const avisSlice = createSlice({
    name: 'avis',
    initialState,
    reducers: {
        fetchReviewsStart: (state) => {
            state.loading = true;
        },
        fetchReviewsSuccess: (state, action) => {
            const {trainingId, avis, total} = action.payload;

            // Vérifier si le trainingId existe déjà
            if (state.verified[trainingId]) {
                // Ajouter les nouveaux avis aux avis existants
                state.verified[trainingId].avis = [...state.verified[trainingId].avis, ...avis];
                state.verified[trainingId].total = total; // Mettre à jour le total
            } else {
                // Créer un nouveau champ pour le trainingId
                state.verified[trainingId] = {
                    avis,
                    total,
                    loading: false
                };
            }

        },
        fetchReviewsFailure: (state) => {
            state.loading = false;
        },
        setSelected: (state, action) => {
            state.selected = action.payload;
        },
    },
});

export const {setSelected, fetchReviewsStart, fetchReviewsSuccess, fetchReviewsFailure} = avisSlice.actions;

export default avisSlice.reducer;