import {trackEvent2} from "../../../../utils/ga";
import {useSelector} from "react-redux";

export const DataSelector = ({selectedType, setSelectedType, data}) => {

    const client = useSelector(state => state.client);

    const handleSelect = (item, index) => {
        setSelectedType(item.key || index)

        if (!client.admin) {
            trackEvent2("select_event", "Client", "select", item.value || item, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }
    return (
        <div className={"row2 data-selector"}>
            {data.map((item, index) => (
                <button key={item.key || index}  // Utilisez key pour une identification unique dans la liste
                        onClick={() => handleSelect(item, index)}
                        className={selectedType === (item.key || index) ? "selected" : ""}>
                    {item.value || item}
                </button>
            ))}
        </div>
    );
}
