import {Images} from "../../Images/Images";
import {getImage} from "../../../utils/APIRoutes";
import defaultpdp from "../../../assets/defaultSchool.png";
import React from "react";
import {countPages} from "../utils";

export const StructureInRow = ({structure, trainings}) => {

    const {premiums, normals} = countPages(trainings);

    return (
        <div className={"row2"}>
            <Images alt={"pdp"} src={(structure?.profilePicture ? getImage + structure.profilePicture : defaultpdp)}
                    styles={"pdp"}/>
            <div>
                <h2 style={{marginBottom: 10}}>{structure?.acronym}</h2>
                <p style={{marginBottom: 10}}>{structure?.description}</p>
                <h3>{premiums} pages premium, {normals} pages classiques</h3>
            </div>
        </div>
    )
}