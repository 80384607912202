import React, {useEffect, useRef, useState} from 'react';
import "./filter.css";
import {Icon} from "../../../Icons/Icon";
import {trackEvent2} from "../../../../utils/ga";
import RangeFilter from "./Types/RangeFilter";
import NormalFilter from "./Types/BasicFilter";
import {useSelector} from "react-redux";


export const Filter = ({filter}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localOptions, setLocalOptions] = useState(filter.options);
    const [rangeValue, setRangeValue] = useState([]);
    const filterRef = useRef(null);
    const [search, setSearch] = useState('');


    useEffect(() => {
        setLocalOptions(filter.options);
        if (filter.type === 'range') {
            setRangeValue([filter.current.min, filter.current.max]);
        }
    }, [filter.options]);

    const arraysAreEqual = (array1, array2) => {
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] && array2[i]) {
                if (array1[i].label !== array2[i].label || array1[i].selected !== array2[i].selected) {
                    return false;
                }
            }
        }
        return true;
    };
    const changes = filter.type === 'range' ? (rangeValue[0] !== filter.current.min || rangeValue[1] !== filter.current.max) : !arraysAreEqual(localOptions, filter.options);
    const client = useSelector(state => state.client);

    const handleOpen = () => {
        setIsOpen(!isOpen);

        if (!client.admin) {
            trackEvent2("filters_event", "Metrics", isOpen ? "close" : "open", filter, {
                structureId: client.structureId,
                acronym: client.acronym
            });
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsOpen(false);
                if (!client.admin) {
                    trackEvent2("filters_event", "Metrics", "close", filter, {
                        structureId: client.structureId,
                        acronym: client.acronym
                    });
                }
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const isFilterActive = () => {
        // count the number of active in  filter.options
        if (filter.type === "range") {
            return filter.current.min !== filter.options.min || filter.current.max !== filter.options.max;
        } else {
            return filter?.options.filter(opt => opt.selected).length > 0;
        }

    }

    return (
        <div ref={filterRef} className={`filter ${isOpen ? "open" : ""} ${isFilterActive() ? "active" : ""}`}>
            <div onClick={() => handleOpen()} className={`row hover ${isOpen ? "open" : ""}`}>
                <h4>{filter.label}</h4>
                <div className={"row2"}>
                    <span
                        className={`${changes ? "changes" : ""}`}>{filter.type !== "range" && localOptions.filter(opt => opt.selected).length}</span>
                    <Icon
                        name="chevron"
                        size={21}
                        type={"stroke"}
                    />
                </div>
            </div>

            {isOpen && (
                <div className={"modal hover"}>

                    {!filter.disableSearch && <div className={"search-container"}>
                        <Icon
                            name="search"
                            size={12}
                            type={"filled"}
                        />
                        <input
                            type="text"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder={filter.placeholder}
                        />
                    </div>}


                    {filter.description && <p style={{paddingTop: 10, opacity: 0.7}}>{filter.description}</p>}


                    {filter.type === "range" ? (
                        <RangeFilter
                            filter={filter}
                            rangeValue={rangeValue}
                            setRangeValue={setRangeValue}
                            setIsOpen={setIsOpen}
                        />
                    ) : (
                        <NormalFilter
                            filter={filter}
                            localOptions={localOptions}
                            setLocalOptions={setLocalOptions}
                            setIsOpen={setIsOpen}
                            search={search}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
