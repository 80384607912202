import {configureStore} from "@reduxjs/toolkit";
import {clientSlice} from "./client";
import {appSlice} from "./app";
import {trainingsSlice} from "./trainings";
import {qnaSlice} from "./Q&A";
import {statsSlice} from "./metrics";
import {leadsSlice} from "./leads";
import {adminSlice} from "./admin";
import {avisSlice} from "./avis";

export const store = configureStore({
    reducer: {
        client: clientSlice.reducer,
        app: appSlice.reducer,
        trainings: trainingsSlice.reducer,
        qna: qnaSlice.reducer,
        stats: statsSlice.reducer,
        leads: leadsSlice.reducer,
        admin: adminSlice.reducer,
        avis: avisSlice.reducer
    }
});


