import {useDispatch, useSelector} from "react-redux";
import {DataSelector} from "../components/Client/Trainings/data/DataSelector";
import {setSelectedFilter} from "../Models/admin";
import {TableAdminDecorator} from "../components/Admin/TableDecorator";
import {Avis} from "../components/Admin/Avis/Avis";
import {TableLeadsDecorator} from "../components/Admin/Leads/TableLeadsDecorator";

export const AdminCenter = () => {

    const dispatch = useDispatch();
    const selectedFilter = useSelector(state => state.admin.selectedFilter);
    const filters = useSelector(state => state.admin.filters);

    const handleFilterChange = (filter) => {
        dispatch(setSelectedFilter(filter));
    }

    const render = () => {
        switch (selectedFilter) {
            case "clients":
                return <TableAdminDecorator/>
            case "avis":
                return <Avis/>
            case "leads":
                return <TableLeadsDecorator/>
        }
    }

    return (
        <div className="fragment">
            <div className="fragment_left stats">
                <DataSelector selectedType={selectedFilter} setSelectedType={handleFilterChange} data={filters}/>
                <div style={{marginTop: 40}}>
                    {render()}
                </div>
            </div>
        </div>
    )

}