import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import LineChart from "../../Graphs/Line/LineChart";
import {getPublicationData} from "./utils";
import {GraphLoader} from "../../../LoadingComponents/GraphLoader";
import {checkIfPremium, countPages} from "../../../Client/utils";
import {NotPremium} from "../../Graphs/NotPremiums/Pie";

export const PublicationsMetrics = ({selectedTraining, loading}) => {
    const selectedPublication = useSelector(state => state.stats.selectedPublication);
    const filters = useSelector((state) => state.stats.filters);

    const from = useSelector(state => state.stats.from);
    const to = useSelector(state => state.stats.to);


    const search = useSelector(state => state.trainings.content);
    const {premiums} = countPages(search);
    const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
    const isPremium = selectedTraining && checkIfPremium(selectedTrainingData) || (!selectedTraining && premiums > 0);

    const chartData = useMemo(() => {
        return getPublicationData(selectedPublication, filters, from, to);
    }, [selectedPublication, selectedTraining, filters]);


    if (!isPremium) {
        return <div className={`rank-container strength-container  ${isPremium ? "" : "locked-graph"}`}>
            <NotPremium/>
        </div>
    }

    if (selectedPublication && chartData.labels.length === 0) {
        return <div
            style={{width: "100%", height: 100, display: "flex", alignItems: "center", justifyContent: "center"}}>Pas de
            données disponibles</div>;
    }


    if (!selectedPublication?.metrics) {
        return <div
            style={{
                width: "100%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>Veuillez sélectionner une publication</div>;
    }

    return (
        <div className={"dashboard"} style={{marginTop: 20, position: "relative"}}>
            <div className={"column"}>
                <div className={`selected row2`}>
                    <h1 className={"numbers"}>
                        {chartData.datasets[0].data.reduce((a, b) => a + b, 0)}
                    </h1>
                    <h3>vues</h3>
                </div>
            </div>
            <div className={"lineChart"}>
                {loading && <GraphLoader/>}
                <LineChart metric={chartData}/>
            </div>
        </div>
    );
};

export default PublicationsMetrics;
