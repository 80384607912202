import {useState} from "react";

const downloadFile = async (url, token) => {
    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            return "error"
        }
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', url.split('/').pop());
        document.body.appendChild(link);
        link.click();
        link.remove();
        return "success"
    } catch (error) {
        console.error("Error downloading the file", error);
        return "error"
    }
};

export const Row = ({row, rowsFormat, onClick}) => {

    const [state, setState] = useState("");

    const handleClick = async (e, column) => {
        e.stopPropagation();
        const result = await downloadFile(`/api/files/file/${row[column]}`, localStorage.getItem("token"))
        setState(result)
    }

    const nativeClick = (e) => {
        e.stopPropagation();
        if (!onClick) return;
        onClick(row)
    }
    return (
        <tr onClick={(e) => nativeClick(e)}>
            {rowsFormat?.map((column, index) => (
                <td key={index}>
                    {column === 'request.fileId' ? (
                        <button
                            style={{borderColor: state === "success" ? "#2ECC71" : state === "error" ? " #E13737" : ""}}
                            disabled={state === "success"}
                            onClick={(e) => handleClick(e, column)}>
                            {state === "success" ? "Téléchargé !" : state === "error" ? "Réessayer" : "Télécharger"}
                        </button>
                    ) : (
                        row[column]
                    )}</td>
            ))}
        </tr>
    );
}