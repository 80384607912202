import PieChart from "../../Graphs/Pie/Pie";
import LineChart from "../../Graphs/Line/LineChart";
import {Column} from "../../Graphs/Column/Column";
import {useSelector} from "react-redux";
import {useMemo, useState} from "react";
import MultiLineChart from "../../Graphs/Line/MultiLineChart";
import {getGlobalVisits, getMultiLineChartData, processPieChartData, sortDataDescending} from "./utils";
import {GraphLoader} from "../../../LoadingComponents/GraphLoader";
import {trackEvent2} from "../../../../utils/ga";
import {checkIfPremium, countPages} from "../../../Client/utils";
import {NotPremium} from "../../Graphs/NotPremiums/Pie";
import {Icon} from "../../../Icons/Icon";

export const Visites = ({selectedTraining}) => {
    const trainings = useSelector((state) => state.stats.trainings);
    const filters = useSelector((state) => state.stats.filters);
    const loading = useSelector(state => state.stats.loading);

    const {from, to} = useSelector(state => state.stats);
    const [onTempo, setOnTempo] = useState(undefined);

    const globalVisits = useMemo(() => {
        if (!loading) {
            const trainingData = selectedTraining ? [trainings[selectedTraining]] : Object.values(trainings);
            let rawVisits = getGlobalVisits(trainingData, filters);
            // Appliquer la transformation sur les données nécessaires
            rawVisits.region = processPieChartData(rawVisits.region);
            rawVisits.filiere = processPieChartData(rawVisits.filiere);
            return rawVisits;
        } else {
            return undefined
        }
    }, [loading, selectedTraining, trainings, filters]);

    const client = useSelector(state => state.client);

    const handleTempo = (type) => {
        setOnTempo(type);

        if (!client.admin) {
            trackEvent2("display_in_time_data", "Metrics", "open", "visites", {
                structureId: client.structureId,
                acronym: client.acronym,
                type: type
            });
        }
    };

    const search = useSelector(state => state.trainings.content);
    const {premiums} = countPages(search);
    const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
    const isPremium = selectedTraining && checkIfPremium(selectedTrainingData) || (!selectedTraining && premiums > 0);

    const multiLineChartData = useMemo(() => {
        if (!onTempo || !isPremium) return undefined;
        return getMultiLineChartData(onTempo, selectedTraining ? [trainings[selectedTraining]] : Object.values(trainings), filters, from, to);

    }, [onTempo, selectedTraining, filters, from, to]);

    return (
        <>
            {onTempo ? (
                <div className="onTemp">
                    <h4 className="row2" style={{cursor: "pointer"}} onClick={() => setOnTempo(undefined)}>
                        <Icon name={"back"} type={"filled"} size={24}/>Retour
                    </h4>
                    <div className={`graph ${isPremium ? "" : "locked-graph"}`} style={{marginTop: 20}}>
                        {loading && <GraphLoader/>}
                        {!isPremium && <NotPremium/>}
                        {isPremium && <MultiLineChart
                            metric={multiLineChartData}
                        />
                        }

                    </div>
                </div>
            ) : (
                <div className="grid-container">
                    <div className="row2" style={{width: "100%"}}>
                        <PieChart
                            metric={globalVisits?.region}
                            loading={loading}
                            handleTempo={() => handleTempo("region")}
                            isPremium={isPremium}
                        />
                        <PieChart
                            metric={globalVisits?.filiere}
                            loading={loading}
                            handleTempo={() => handleTempo("filiere")}
                            isPremium={isPremium}
                        />
                    </div>


                    <div className={`line-chart graph ${isPremium ? "" : "locked-graph"}`} style={{transition: "none"}}>
                        {isPremium && loading && <GraphLoader/>}
                        {!isPremium && <NotPremium/>}
                        {globalVisits?.all && isPremium && <LineChart metric={globalVisits?.all}/>}
                    </div>

                    {isPremium ?
                        <Column
                            loading={loading}
                            metric={sortDataDescending(globalVisits?.localisation)}
                            handleTempo={() => handleTempo("localisation")}
                            isPremium={isPremium}
                        />
                        : <div className={"column graph locked-graph"}>
                            <NotPremium title={"Localisation des visiteurs"}/>
                        </div>
                    }
                </div>
            )}
        </>
    );
};