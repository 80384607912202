import {Route, Routes} from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import {LandingPage} from "../pages/Landing/Landing";


export const DefaultRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route
                path="/login"
                element={
                    <Login
                    />
                }
            />

            <Route
                path="/register"
                element={
                    <Register
                    />
                }
            />
        </Routes>
    )
}